
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ErrorHandler, ViewChild, HostListener } from '@angular/core';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/header/header.service';
import { Location } from '@angular/common';
import { isValid } from 'date-fns';
import { ThrowStmt } from '@angular/compiler';
import { EmailConfigurationForDueDatesService } from '../email-configuration-for-due-dates.service';
@Component({
  selector: 'app-add-update-email-configuration-for-due-dates',
  templateUrl: './add-update-email-configuration-for-due-dates.component.html',
  styleUrls: ['./add-update-email-configuration-for-due-dates.component.scss']
})
export class AddUpdateEmailConfigurationForDueDatesComponent implements OnInit, AfterViewInit {

  @Output() BackEventEmitter = new EventEmitter<string>();
  editorContent: string = '';
  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };
  public audittypes = ["AP", "KP", "RP", "OT"];
  public audittypeleadlist = ["EY", "IR", "NA"];
  public duedatelist1 = ["Audit Period Start Date", "Audit Period End Date", "Audit Execution Start Date", "Audit Execution End Date", "IA Work Completed", "Announcement Delivery", "Client Data Due", "Draft Report", "IA Work Paper Review Due", "Draft Report To Client Due", "Client Feedback Due", "Final Report To Client Due"];
  public duedatelist2 =["Audit Period Start Date", "Audit Period End Date", "Audit Execution Start Date", "Audit Execution End Date", "IA Work Completed","Decisive Date","Latest Sign-off Date", "Actual Sign-off Date", "Announcement Delivery", "Client Data Due", "Draft Report", "IA Work Paper Review Due", "Draft Report To Client Due", "Client Feedback Due", "Final Report To Client Due"];
  public triggertypelist = ["Prior to Due Date","On Due Date", "After Due Date"]
  public sendtolist = [];
  public sendtolist1=["Engagement Partner","Engagement Manager","FINMA Lead Auditor","Internal Audit Engagement Leader"];
  public sendtolist2=["Engagement Partner","Engagement Manager","FINMA Lead Auditor","Internal Audit Engagement Leader","Reg. Audit Co-Sign"];
  public sendcclist = ["Engagement Partner", "Engagement Manager", "FINMA Lead Auditor", "Internal Audit Engagement Leader"];

  public languagelist: any;
  public reportingLanguageName: any;
  public reportingLanguageId: any;
  isSelected: boolean = true;
  dummyshow: boolean = true;
  public AlreadySelected: boolean = false;

  @ViewChild('editor') editor;

  public lstAuditTypeAP: any = [];
  public lstAuditTypeKP: any = [];
  public lstAuditTypeRP: any = [];
  public lstAuditTypeOT: any = [];
  public textShow: boolean = false;
  EditParams: any;
  public dataTable=[];
  

  constructor(private router: Router, private _headerService: HeaderService, private _EmailConfigservice: EmailConfigurationForDueDatesService,
    private _formBuilder: FormBuilder, private location: Location) { }

  public loaderflag: boolean = false;

  //#region ag-grid_variables
  modules = AllCommunityModules;
  gridApi: any;
  public style = {
    marginTop: "",
    width: "",
    height: ""
  };
  public btnsubmittext: string = "ADD";
  public overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  public ColumnDefs: any;
  public frameworkComponents: any;

  public tableDataset: any;
  public deletemodalflag: boolean = false;
  //#endregion ag-grid_variables
  public Escmodalflag:boolean=false;


  form: FormGroup;
  public EditID: any;
  public DeletID: any;
  public ISUpdate: boolean = false;
  public showLoader: boolean = false;
  public rowData: any;
  public btnflag: boolean = true;
  public duedatelist=[];
  @Input() Params;
  @Input() auditassign;
  @Input() advanceUpdate;
  public editdata: any;

  //#region  toast
  public toasttype: string;
  public toastflag: boolean;
  public toastmessage: string;
  //#endregion toast

  ////#region actions variables
  public divFlag: boolean = false;
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public actionAccess: any;
  ////#endregion

  ngOnInit(): void {
    this.form = this.createForm();
    this.GetAudittype(this.Params);   
    this.setDivHeight();
    this.ValidationsforAuditTypes();
    if(this.Params == undefined){
      if(this.form.value.audittype=="AP"){
        this.sendtolist= this.sendtolist2;
        this.dataTable=this.dataTable2;
        this.duedatelist=this.duedatelist1;
      }
      else if(this.form.value.audittype=="RP"){
        this.dataTable=this.dataTable3;
        this.sendtolist= this.sendtolist1;
        this.duedatelist=this.duedatelist2;
      }
      else{
      this.sendtolist= this.sendtolist1;
      this.dataTable=this.dataTable1;
      this.duedatelist=this.duedatelist1;
      }
    }
    else{
      if(this.Params.auditType=="AP"){
        this.sendtolist= this.sendtolist2;
        this.dataTable=this.dataTable2;
        this.duedatelist=this.duedatelist1;
      }
      else if(this.Params.auditType=="RP"){
        this.dataTable=this.dataTable3;
        this.sendtolist= this.sendtolist1;
        this.duedatelist=this.duedatelist2;
      }
      else{
      this.sendtolist= this.sendtolist1;
      this.dataTable=this.dataTable1;  
      this.duedatelist=this.duedatelist1;
      }
    }
    
    this._headerService.getActions("EmailConfigDueDates").then((value) => {
      this.actionAccess = value;
      if (this.actionAccess.indexOf("View") != -1) {
        this.divFlag = true;
      }
      else
        this.router.navigate(['/unauthorizedaccess'])
    });
  }

  onCopyHTMLClick() {
    let body=this.form.get("emailbody").value; 
    if(body!=null){     
    const textArea = document.createElement('textarea');
    textArea.value = body;
   // Ensure it is invisible and added to the DOM
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '0';
    textArea.style.height = '0';
    document.body.appendChild(textArea);
   // Select and copy the text
    textArea.select();
    document.execCommand('copy');
   // Clean up and remove the temporary textarea
    document.body.removeChild(textArea);
    this.toasttype = "success";
    this.toastflag = true;
    this.toastmessage = "Successfully copied the HTML format";
    setTimeout(() => {
      this.toastflag=false;
     }, 3000);
    }
    else{
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage =  "Text Editor should not be empty.";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
    } 
  }

  ngAfterViewInit(): void {
  }

  public EmailConfurationId: any;
  public multiSelectValues: any;
  selectAuditStatus: any;
  editdetails(data) {
    if(data.auditType=="AP"){
      this.sendtolist= this.sendtolist2;
      this.dataTable=this.dataTable2;
      this.duedatelist=this.duedatelist1;
    }
    else if(data.auditType=="RP"){
      this.dataTable=this.dataTable3;
      this.sendtolist= this.sendtolist1;
      this.duedatelist=this.duedatelist2;
    }
    else{
    this.sendtolist= this.sendtolist1;
    this.dataTable=this.dataTable1;
    this.duedatelist=this.duedatelist1;
    }
    

    this.EditParams = data;

    this.btnflag = false;
    this.dummyshow = false;
    this.AlreadySelected = true;
    this.EmailConfurationId = data.emailConfigurationId;
    let sendTo = null;
    let sendCc = null;
    let auditTypeLead = null;
    let additionalEmails = null;
    let auditStatusAP = null;
    let auditStatusKP = null;
    let auditStatusRP = null;
    let auditStatusOT = null;
    let language=null;


    let STIDS = data.sendTo;
    if (STIDS != null) {
      ;
      sendTo = STIDS.split(',');
      for (var i = 0; i < sendTo.length; i++) { sendTo[i] = sendTo[i]; }
    }

    let SCCIDS = data.sendCc;
    ;
    if (SCCIDS != null) {
      sendCc = SCCIDS.split(',');
      for (var i = 0; i < sendCc.length; i++) { sendCc[i] = sendCc[i]; }
    }

    let addtemails = data.additionalEmails;
    ;
    if (addtemails != null) {
      this.textShow = true;
      addtemails = data.additionalEmails.split(',').sort((a, b) => a - b).map(x => x).join("\n");
    }
    else {
      this.textShow = false;
      additionalEmails = null;
    }

    let ATLIDS = data.auditTypeLead;
    ;
    if (ATLIDS != null) {
      auditTypeLead = ATLIDS.split(',');
      for (var i = 0; i < auditTypeLead.length; i++) { auditTypeLead[i] = auditTypeLead[i]; }
    }

    let ASAP = data.auditStatusAp;
    ;
    if (ASAP != null) {
      auditStatusAP = ASAP.split(',');
      for (var i = 0; i < auditStatusAP.length; i++) { auditStatusAP[i] = +auditStatusAP[i]; }
      this.lstAuditTypeAP.forEach(element => {      
        if (auditStatusAP.indexOf(element.auditTypeStatusApId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
  
    }
    else {
      auditStatusAP = null;
    }
    // let auditStatusAPnum = null;
    // length = auditStatusAP.length;
    // for (var i = 0; i < length; i++)
    // auditStatusAPnum[i]=+auditStatusAP[i];
    let ASKP = data.auditStatusKp;
    ;
    if (ASKP != null) {
      auditStatusKP = ASKP.split(',');
      for (var i = 0; i < auditStatusKP.length; i++) { auditStatusKP[i] = +auditStatusKP[i]; }
      this.lstAuditTypeKP.forEach(element => {      
        if (auditStatusKP.indexOf(element.auditTypeStatusKpId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
    }
    else {
      auditStatusKP = null;
    }

    let ASRP = data.auditStatusRp;
    ;
    if (ASRP != null) {
      auditStatusRP = ASRP.split(',');
      for (var i = 0; i < auditStatusRP.length; i++) { auditStatusRP[i] = +auditStatusRP[i]; }
      this.lstAuditTypeRP.forEach(element => {      
        if (auditStatusRP.indexOf(element.auditTypeStatusRpId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
    }
    else {
      auditStatusRP = null;
    }

    let ASOT = data.auditStatusOt;
    ;
    if (ASOT != null) {
      auditStatusOT = ASOT.split(',');
      for (var i = 0; i < auditStatusOT.length; i++) { auditStatusOT[i] = +auditStatusOT[i]; }
      this.lstAuditTypeOT.forEach(element => {      
        if (auditStatusOT.indexOf(element.auditTypeStatusOtId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
    }
    else {
      auditStatusOT = null;
    }


    let LangIDS = data.language;
    ;
    if (LangIDS != null) {
      language = LangIDS.split(',');
      for (var i = 0; i < language.length; i++) { language[i] = +language[i]; }
      this.languagelist.forEach(element => {      
        if (language.indexOf(element.reportingLanguageId) != -1)
          element.isChecked = true;
        else
          element.isChecked = false;
      });
  
    }
    else {
      language = null;
    }





    console.log(data);

    this.form.patchValue({
      configurationtitle: data.configurationTitle,
      audittype: data.auditType,
      auditstatusap: auditStatusAP,
      auditstatuskp: auditStatusKP,
      auditstatusrp: auditStatusRP,
      auditstatusot: auditStatusOT,
      audittypelead: auditTypeLead,
      duedate: data.dueDate,
      triggerperiod: data.triggerPeriod,
      triggertype: data.triggerType,
      isenabled: data.isEnabled,
      sendto: sendTo,
      sendcc: sendCc,
      additionalemails: addtemails,
      language: language,
      emailsubject: data.emailSubject,
      emailbody: data.emailBody,
    })
    console.log(this.form.value)

  }


  TriggerTypechange(value){
    debugger;
    if(value=="On Due Date"){
      this.form.patchValue({
        triggerperiod: 0,
      });
    
      this.form.controls['triggerperiod'].disable();
    }
    else{
      this.form.patchValue({
        triggerperiod: null,
      });
      this.form.controls['triggerperiod'].enable();
    }
  }

  

  createForm() {
    return this._formBuilder.group({
      configurationtitle: [null, [Validators.required]],
      audittype: [null, [Validators.required]],
      auditstatusap: [null],
      auditstatuskp: [null],
      auditstatusrp: [null],
      auditstatusot: [null],
      audittypelead: [null, [Validators.required]],
      duedate: [null, [Validators.required]],
      triggerperiod: [null, [Validators.required]],
      triggertype: [null, [Validators.required]],
      isenabled: [null],
      sendto: [null, [Validators.required]],
      sendcc: [null],
      additionalemails: [null],
      language: [null, [Validators.required]],
      emailsubject: [null, [Validators.required]],
      emailbody: [null, [Validators.required]],
    })
  }

  submit() {
    this.validateForm(this.form);
    this.ValidationsforAuditTypes();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.ISUpdate) {
        this.update();
      } else {
        this.Add();
      }
    }
    else {
      this.validateForm(this.form);
    }
  }

  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  Add() {
    ;
    // let i;
    this.validateForm(this.form);
    this.ValidationsforAuditTypes();
    if (this.form.valid) {
      this.showLoader = true;
      let configurationtitle = this.form.get("configurationtitle").value;
      let audittype = this.form.get("audittype").value;
      // let auditstatusap = this.form.get("auditstatusap").value;
      // let auditstatuskp = this.form.get("auditstatuskp").value;
      // let auditstatusrp = this.form.get("auditstatusrp").value;
      // let auditstatusot = this.form.get("auditstatusot").value;
      let auditstatusap = this.form.get("auditstatusap").value;
      if (auditstatusap != null) {
        auditstatusap = this.form.value.auditstatusap.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusap = null;
      }

      let auditstatuskp = this.form.get("auditstatuskp").value;
      if (auditstatuskp != null) {
        auditstatuskp = this.form.value.auditstatuskp.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatuskp = null;
      }

      let auditstatusrp = this.form.get("auditstatusrp").value;
      if (auditstatusrp != null) {
        auditstatusrp = this.form.value.auditstatusrp.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusrp = null;
      }

      let auditstatusot = this.form.get("auditstatusot").value;
      if (auditstatusot != null) {
        auditstatusot = this.form.value.auditstatusot.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusot = null;
      }
      let audittypelead = this.form.value.audittypelead.sort((a, b) => a - b).map(x => x).join(",");
      let duedate = this.form.get("duedate").value;
      let triggerperiod = this.form.get("triggerperiod").value;
      let triggertype = this.form.get("triggertype").value;
      let isenabled = this.form.get("isenabled").value == true ? true : false;
      let sendto = this.form.value.sendto.sort((a, b) => a - b).map(x => x).join(",");
      let sendcc = this.form.value.sendcc.sort((a, b) => a - b).map(x => x).join(",");
      let additionalemails = this.form.get("additionalemails").value;
      if (additionalemails != null) {
        additionalemails = this.form.value.additionalemails.split('\n').sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        additionalemails = null;
      }
      let language = this.form.value.language.sort((a, b) => a - b).map(x => x).join(",");
      let emailsubject = this.form.get("emailsubject").value;
      let body=this.form.get("emailbody").value;      
      let emailbody =btoa(unescape(encodeURIComponent(body)));

      let addobj = {
        ConfigurationTitle: configurationtitle,
        AuditType: audittype,
        AuditStatusAp: auditstatusap,
        AuditStatusKp: auditstatuskp,
        AuditStatusRp: auditstatusrp,
        AuditStatusOt: auditstatusot,
        AuditTypeLead: audittypelead,
        DueDate: duedate,
        TriggerPeriod: triggerperiod,
        TriggerType: triggertype,
        IsEnabled: isenabled,
        SendTo: sendto,
        SendCC: sendcc,
        AdditionalEmails: additionalemails,
        Language: language,
        EmailSubject: emailsubject,
        EmailBody: emailbody,
      }
      ;
      this._EmailConfigservice.Add(addobj)
        .subscribe(
          (res: any) => {
            if (res.message == "success") {
              this.toasttype = "success";
              this.toastflag = true;
              this.toastmessage = "Email Configuration has been added successfully.";
              this.reset();
              console.log(res);
              this.router.navigate(['/EmailConfigurationForDueDates']);
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else if (res.message == "EmailConfigurationExists") {
              this.showLoader = false;
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Email Configuration already exist.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
          },
          err => {
            if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
              this.toasttype = "warning";
              this.toastflag = true;
              this.toastmessage = err.toString();
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else {
              this.reset();
            }
          }
        )
    }
  }

  oncancel(status: string) {
    this.location.go('/EmailConfigurationForDueDates/0');
    this.loaderflag = true;
    this.BackEventEmitter.emit(status);
    this.router.navigate(['/EmailConfigurationForDueDates']);
  }

  update() {
    ;
    this.validateForm(this.form);
    this.ValidationsforAuditTypes();
    if (this.form.valid) {
      this.showLoader = true;
      let configurationtitle = this.form.get("configurationtitle").value;
      let audittype = this.form.get("audittype").value;
      // let auditstatusap = this.form.get("auditstatusap").value;
      // let auditstatuskp = this.form.get("auditstatuskp").value;
      // let auditstatusrp = this.form.get("auditstatusrp").value;
      // let auditstatusot = this.form.get("auditstatusot").value;
      let auditstatusap = this.form.get("auditstatusap").value;
      if (auditstatusap != null) {
        auditstatusap = this.form.value.auditstatusap.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusap = null;
      }

      let auditstatuskp = this.form.get("auditstatuskp").value;
      if (auditstatuskp != null) {
        auditstatuskp = this.form.value.auditstatuskp.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatuskp = null;
      }

      let auditstatusrp = this.form.get("auditstatusrp").value;
      if (auditstatusrp != null) {
        auditstatusrp = this.form.value.auditstatusrp.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusrp = null;
      }

      let auditstatusot = this.form.get("auditstatusot").value;
      if (auditstatusot != null) {
        auditstatusot = this.form.value.auditstatusot.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        auditstatusot = null;
      }
      let audittypelead = this.form.value.audittypelead.sort((a, b) => a - b).map(x => x).join(",");
      let duedate = this.form.get("duedate").value;
      let triggerperiod = this.form.get("triggerperiod").value;
      let triggertype = this.form.get("triggertype").value;
      let isenabled = this.form.get("isenabled").value;
      let sendto = this.form.value.sendto.sort((a, b) => a - b).map(x => x).join(",");
      let sendcc = this.form.value.sendcc.sort((a, b) => a - b).map(x => x).join(",");
      let additionalemails = this.form.get("additionalemails").value;
      if (additionalemails != null) {
        additionalemails = this.form.value.additionalemails.split('\n').sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        additionalemails = null;
      }
      let language=this.form.get("language").value;
      if (language != null) {
        language = this.form.value.language.sort((a, b) => a - b).map(x => x).join(",");
      }
      else {
        language = null;
      }
      // let language = this.form.value.language.sort((a, b) => a - b).map(x => x).join(",");
      let emailsubject = this.form.get("emailsubject").value;
      let body=this.form.get("emailbody").value;      
      let emailbody =btoa(unescape(encodeURIComponent(body)));

      let upobj = {
        emailConfigurationId: this.EmailConfurationId,
        ConfigurationTitle: configurationtitle,
        AuditType: audittype,
        AuditStatusAp: auditstatusap,
        AuditStatusKp: auditstatuskp,
        AuditStatusRp: auditstatusrp,
        AuditStatusOt: auditstatusot,
        AuditTypeLead: audittypelead,
        DueDate: duedate,
        TriggerPeriod: triggerperiod,
        TriggerType: triggertype,
        IsEnabled: isenabled,
        SendTo: sendto,
        SendCC: sendcc,
        AdditionalEmails: additionalemails,
        Language: language,
        EmailSubject: emailsubject,
        EmailBody: emailbody,
      }

      this._EmailConfigservice.Update(upobj)
        .subscribe(
          (res: any) => {
            if (res.message == "EmailConfigurationExists") {
              this.showLoader = false;
              this.toasttype = "error";
              this.toastflag = true;
              this.toastmessage = "Email Configuration already exists.";
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else
              if (res.message == "success") {
                this.reset();
                this.toasttype = "success";
                this.toastflag = true;
                this.oncancel("update");
                this.toastmessage = "Email Configuration has been updated successfully.";
                setTimeout(() => {
                  this.toastflag=false;
                 }, 3000);
              } else
                if (res.message == "fail") {
                  {
                    this.reset();
                    this.toasttype = "error";
                    this.toastflag = true;
                    this.toastmessage = "An unexpected error has occured. Please contact support team.";
                    setTimeout(() => {
                      this.toastflag=false;
                     }, 3000);
                  }
                }
          },
          err => {
            
            if (err.toString().toLocaleLowerCase().indexOf('max') != -1 || err.toString().toLocaleLowerCase().indexOf('allowed') != -1) {
              this.toasttype = "warning";
              this.toastflag = true;
              this.toastmessage = err.toString();
              setTimeout(() => {
                this.toastflag=false;
               }, 3000);
            }
            else {
              this.reset();
            }
          }
        )
    }
  }

  

  reset() {
    ;
    this.dummyshow = true;
    this.form.patchValue({
      sendto: null,
      sendcc: null,
    });
    this.showLoader = false;
    this.ISUpdate = false;
    this.EditID = null;
    this.DeletID = null;

    this.btnsubmittext = "ADD";
    this.form.reset();
  }

  ////#region Action check method
  ActionsCheck(action) {
    if (this.actionAccess.indexOf(action) != -1)
      return true;
    else
      return false;
  }
  setDivHeight() {
    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 150) + "px";
  }

  public pageload: boolean = false;
  GetAudittype(data) {
    ;
    this._EmailConfigservice.GetAudittype()
      .subscribe(
        res => {
          
          console.log(res)
          this.lstAuditTypeAP = res[0];
          console.log(this.lstAuditTypeAP = res[0])
          this.lstAuditTypeKP = res[1];
          this.lstAuditTypeRP = res[2];
          this.lstAuditTypeOT = res[3];
          
          this.pageload = true;
       this.getalllanguages(data);
        },
        err => {
          console.log(err);
        }
      )
  }

  Addmail() {
    this.textShow = true;
    this.form.get('additionalemails').setValidators(Validators.required);
  }

  get additionalemails(): any { return this.form.get('additionalemails'); }
  Removemail() {
    this.textShow = false;
    this.additionalemails.reset();
    this.form.get('additionalemails').clearValidators();
    this.form.get('additionalemails').updateValueAndValidity();
  }


  ValidationsforAuditTypes() {
    this.form.get('audittype').valueChanges
      .subscribe(value => {
        if (value == 'AP') {
          this.form.get('auditstatusap').setValidators(Validators.required);
          this.form.get('auditstatuskp').setValidators(null);
          this.form.get('auditstatusrp').setValidators(null);
          this.form.get('auditstatusot').setValidators(null);
        }
        else if (value == 'KP') {
          this.form.get('auditstatuskp').setValidators(Validators.required);
          this.form.get('auditstatusap').setValidators(null);
          this.form.get('auditstatusrp').setValidators(null);
          this.form.get('auditstatusot').setValidators(null);
        }
        else if (value == 'RP') {
          this.form.get('auditstatusrp').setValidators(Validators.required);
          this.form.get('auditstatusap').setValidators(null);
          this.form.get('auditstatuskp').setValidators(null);
          this.form.get('auditstatusot').setValidators(null);
        }
        else if (value == 'OT') {
          this.form.get('auditstatusot').setValidators(Validators.required);
          this.form.get('auditstatusap').setValidators(null);
          this.form.get('auditstatuskp').setValidators(null);
          this.form.get('auditstatusrp').setValidators(null);
        } else {
          this.form.get('auditstatusap').setValidators(null);
          this.form.get('auditstatuskp').setValidators(null);
          this.form.get('auditstatusrp').setValidators(null);
          this.form.get('auditstatusot').setValidators(null);
        }
        this.form.get('auditstatusap').updateValueAndValidity();
        this.form.get('auditstatuskp').updateValueAndValidity();
        this.form.get('auditstatusrp').updateValueAndValidity();
        this.form.get('auditstatusot').updateValueAndValidity();
      }
      );
  }
  ////#endregion

  public dataTable1 = [{ myid: 1, columnname: 'Bank code', columnvalue: '[[Bank code]]' }, { myid: 2, columnname: 'Bank Short Name', columnvalue: '[[Bank Short Name]]' }, { myid: 3, columnname: 'Audit Type', columnvalue: '[[Audit Type]]' }, { myid: 4, columnname: 'AuditIDCode', columnvalue: '[[AuditIDCode]]' }, { myid: 5, columnname: 'Audit Period Start Date', columnvalue: '[[Audit Period Start Date]]' }, { myid: 6, columnname: 'Audit Period End Date', columnvalue: '[[Audit Period End Date]]' }, { myid: 7, columnname: 'Audit Execution Start Date', columnvalue: '[[Audit Execution Start Date]]' }, { myid: 8, columnname: 'Audit Execution End Date', columnvalue: '[[Audit Execution End Date]]' }, { myid: 9, columnname: 'IA Work Completed', columnvalue: '[[IA Work Completed]]' }, { myid: 10, columnname: 'Announcement Delivery', columnvalue: '[[Announcement Delivery]]' }, { myid: 11, columnname: 'Client Data Due', columnvalue: '[[Client Data Due]]' }, { myid: 12, columnname: 'Draft Report', columnvalue: '[[Draft Report]]' },
  { myid: 13, columnname: 'IA Work Paper Review Due', columnvalue: '[[IA Work Paper Review Due]]' }, { myid: 14, columnname: 'Draft Report To Client Due', columnvalue: '[[Draft Report To Client Due]]' }, { myid: 15, columnname: 'Client Feedback Due', columnvalue: '[[Client Feedback Due]]' }, { myid: 16, columnname: 'Final Report To Client Due', columnvalue: '[[Final Report To Client Due]]' }, { myid: 17, columnname: 'EngagementPartner_FirstName', columnvalue: '[[EngagementPartner_FirstName]]' }, { myid: 18, columnname: 'EngagementPartner_LastName', columnvalue: '[[EngagementPartner_LastName]]' }, { myid: 19, columnname: 'EngagementManager_FirstName', columnvalue: '[[EngagementManager_FirstName]]' }, { myid: 20, columnname: 'EngagementManager_LastName', columnvalue: '[[EngagementManager_LastName]]' }, { myid: 21, columnname: 'FINMA Lead Auditor_FirstName', columnvalue: '[[FINMA Lead Auditor_FirstName]]' }, { myid: 22, columnname: 'FINMA Lead Auditor_LastName', columnvalue: '[[FINMA Lead Auditor_LastName]]' }, { myid: 23, columnname: 'Internal Audit Engagement Leader_FirstName', columnvalue: '[[Internal Audit Engagement Leader_FirstName]]' }, { myid: 24, columnname: 'Internal Audit Engagement Leader_LastName', columnvalue: '[[Internal Audit Engagement Leader_LastName]]'},{myid:25,columnname:'Update Link',columnvalue:'[[Update Link]]'},{myid:26,columnname:'View Link',columnvalue:'[[View Link]]'}]

  public dataTable2=  [{myid:1,columnname:'Bank code',columnvalue:'[[Bank code]]'},{myid:2,columnname:'Bank Short Name',columnvalue:'[[Bank Short Name]]'},{myid:3,columnname:'Audit Type',columnvalue:'[[Audit Type]]'},{myid:4,columnname:'AuditIDCode',columnvalue:'[[AuditIDCode]]'},{myid:5,columnname:'Audit Period Start Date',columnvalue:'[[Audit Period Start Date]]'},{myid:6,columnname:'Audit Period End Date',columnvalue:'[[Audit Period End Date]]'},{myid:7,columnname:'Audit Execution Start Date',columnvalue:'[[Audit Execution Start Date]]'},{myid:8,columnname:'Audit Execution End Date',columnvalue:'[[Audit Execution End Date]]'},{myid:9,columnname:'IA Work Completed',columnvalue:'[[IA Work Completed]]'},{myid:10,columnname:'Announcement Delivery',columnvalue:'[[Announcement Delivery]]'},{myid:11,columnname:'Client Data Due',columnvalue:'[[Client Data Due]]'},{myid:12,columnname:'Draft Report',columnvalue:'[[Draft Report]]'},
  {myid:13,columnname:'IA Work Paper Review Due',columnvalue:'[[IA Work Paper Review Due]]'},{myid:14,columnname:'Draft Report To Client Due',columnvalue:'[[Draft Report To Client Due]]'},{myid:15,columnname:'Client Feedback Due',columnvalue:'[[Client Feedback Due]]'},{myid:16,columnname:'Final Report To Client Due',columnvalue:'[[Final Report To Client Due]]'},{myid:17,columnname:'EngagementPartner_FirstName',columnvalue:'[[EngagementPartner_FirstName]]'},{myid:18,columnname:'EngagementPartner_LastName',columnvalue:'[[EngagementPartner_LastName]]'},{myid:19,columnname:'EngagementManager_FirstName',columnvalue:'[[EngagementManager_FirstName]]'},{myid:20,columnname:'EngagementManager_LastName',columnvalue:'[[EngagementManager_LastName]]'},{myid:21,columnname:'FINMA Lead Auditor_FirstName',columnvalue:'[[FINMA Lead Auditor_FirstName]]'},{myid:22,columnname:'FINMA Lead Auditor_LastName',columnvalue:'[[FINMA Lead Auditor_LastName]]'},{myid:23,columnname:'Internal Audit Engagement Leader_FirstName',columnvalue:'[[Internal Audit Engagement Leader_FirstName]]'},{myid:24,columnname:'Internal Audit Engagement Leader_LastName',columnvalue:'[[Internal Audit Engagement Leader_LastName]]'},
  {myid:25,columnname:'Reg. Audit Co-Sign_FirstName',columnvalue:'[[Reg. Audit Co-Sign_FirstName]]'},{myid:26,columnname:'Reg. Audit Co-Sign_LastName',columnvalue:'[[Reg. Audit Co-Sign_LastName]]'},{myid:27,columnname:'Update Link',columnvalue:'[[Update Link]]'},{myid:28,columnname:'View Link',columnvalue:'[[View Link]]'},]
  
  public dataTable3 = [{ myid: 1, columnname: 'Bank code', columnvalue: '[[Bank code]]' }, { myid: 2, columnname: 'Bank Short Name', columnvalue: '[[Bank Short Name]]' }, { myid: 3, columnname: 'Audit Type', columnvalue: '[[Audit Type]]' }, { myid: 4, columnname: 'AuditIDCode', columnvalue: '[[AuditIDCode]]' }, { myid: 5, columnname: 'Audit Period Start Date', columnvalue: '[[Audit Period Start Date]]' }, { myid: 6, columnname: 'Audit Period End Date', columnvalue: '[[Audit Period End Date]]' }, { myid: 7, columnname: 'Audit Execution Start Date', columnvalue: '[[Audit Execution Start Date]]' }, { myid: 8, columnname: 'Audit Execution End Date', columnvalue: '[[Audit Execution End Date]]' }, { myid: 9, columnname: 'IA Work Completed', columnvalue: '[[IA Work Completed]]' }, { myid: 10, columnname: 'Decisive Date',columnvalue:'[[Decisive Date]]'},{myid:11,columnname:'Latest Sign-off Date',columnvalue:'[[Latest Sign-off Date]]'},{myid:12,columnname:'Actual Sign-off Date',columnvalue:'[[Actual Sign-off Date]]'},{myid:13,columnname:'Announcement Delivery',columnvalue:'[[Announcement Delivery]]'},{myid:14,columnname:'Client Data Due',columnvalue:'[[Client Data Due]]'},{myid:15,columnname:'Draft Report',columnvalue:'[[Draft Report]]'},
  {myid:16,columnname:'IA Work Paper Review Due',columnvalue:'[[IA Work Paper Review Due]]'},{myid:17,columnname:'Draft Report To Client Due',columnvalue:'[[Draft Report To Client Due]]'},{myid:18,columnname:'Client Feedback Due',columnvalue:'[[Client Feedback Due]]'},{myid:19,columnname:'Final Report To Client Due',columnvalue:'[[Final Report To Client Due]]'},{myid:20,columnname:'EngagementPartner_FirstName',columnvalue:'[[EngagementPartner_FirstName]]'},{myid:21,columnname:'EngagementPartner_LastName',columnvalue:'[[EngagementPartner_LastName]]'},{myid:22,columnname:'EngagementManager_FirstName',columnvalue:'[[EngagementManager_FirstName]]'},{myid:23,columnname:'EngagementManager_LastName',columnvalue:'[[EngagementManager_LastName]]'},{myid:24,columnname:'FINMA Lead Auditor_FirstName',columnvalue:'[[FINMA Lead Auditor_FirstName]]'},{myid:25,columnname:'FINMA Lead Auditor_LastName',columnvalue:'[[FINMA Lead Auditor_LastName]]'},{myid:26,columnname:'Internal Audit Engagement Leader_FirstName',columnvalue:'[[Internal Audit Engagement Leader_FirstName]]'},{myid:27,columnname:'Internal Audit Engagement Leader_LastName',columnvalue:'[[Internal Audit Engagement Leader_LastName]]'},
  {myid:25,columnname:'Update Link',columnvalue:'[[Update Link]]'},{myid:26,columnname:'View Link',columnvalue:'[[View Link]]'},]
  
  AddColData(i) {
    ;
    const selection = this.editor.quillEditor.getSelection();
    this.editor.quillEditor.insertText(selection.index, this.dataTable[i].columnvalue);
  }

  listEn: any = [];
  getalllanguages(data) {
    ;
    this._EmailConfigservice.GetMastersData().subscribe(res => {
      console.log(res);
      this.languagelist = res[0];
      this.languagelist.forEach(element => {
        this.reportingLanguageName = element;
        this.reportingLanguageId = element;
        console.log(element);
      });
      console.log(this.languagelist);
      this.pageload = true;
      if (data != null) {
        if (data.length == undefined) {
          
          this.editdetails(data);
        }
      }
    })
  }

  CLOSE() {
    ;
    this.router.navigate(['/EmailConfiguration']);
  }
  Auditchange() {
    ;
    this.dummyshow = false;

    if(this.form.value.audittype=="AP"){
      this.sendtolist= this.sendtolist2;
      this.dataTable=this.dataTable2;
      this.duedatelist=this.duedatelist1;
    }
    else if(this.form.value.audittype=="RP"){
      this.dataTable=this.dataTable3;
      this.sendtolist= this.sendtolist1;
      this.duedatelist=this.duedatelist2;
    }
    else{
    this.sendtolist= this.sendtolist1;
    this.dataTable=this.dataTable1;
    this.duedatelist=this.duedatelist1;
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    debugger;
    let updateid = this._EmailConfigservice.getupdateData();
    if(updateid.newflag == "AUDupdate"){
      this.Escmodalflag=true;
    }
    else{
      this.oncancel('cancel');
    }
  }  

}

