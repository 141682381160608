<div>
  <form [formGroup]="form" novalidate>
    <div class="motif-row">
      <div class="motif-col-xs-2 motif-col-md-2 motif-col-lg-2 headingcol">
        <div class="motif-modal-header">
          <h3 class="motif-h3"> Audit Assignment</h3>
          </div>
      </div>
      <div class="motif-col-xs-7 motif-col-md-7 motif-col-lg-7 formtitlecol">
        <fieldset [disabled]='auditassign' style="border: 0; padding: 0;"> 
               <motif-form-field>
               <label motifLabel>Form Title</label>
               <input motifInput type="text" disabled aria-label="Form Title Form Field" formControlName="formtitle" />
               </motif-form-field>
          </fieldset>
      </div>
      <div class="motif-col-xs-3 motif-col-md-3 motif-col-lg-3"> 
          <div class="canceldiv">
            <button motifButton id="btnCancelauditassign"  (click)="oncancel('cancel')" color="secondary"><motif-icon class="cancelicon" [src]="'/assets/icons/content/ic_clear_24px.svg'"></motif-icon></button>
          </div>
        </div>
  
      </div>
      <fieldset [disabled]='auditassign' style="border: 0;">
        <!-- <span class="fonts-col__heading-4"><b> Entity Details </b></span> -->
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Entity Name</label>
            <input motifInput type="text" disabled aria-label="Entity Name Form Field" formControlName="entityname" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Audit Year</label>
            <input motifInput type="text" disabled aria-label="Audit Year Form Field" formControlName="audityear" />
          </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Classification</label>
            <input motifInput type="text" disabled aria-label="classification Form Field"
              formControlName="classification" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Group Scope</label>
            <input motifInput type="text" disabled aria-label="Group Scope Form Field" formControlName="groupscope" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Form Status</label>
            <input motifInput type="text" disabled aria-label="Form Status Form Field" formControlName="formstatus" />
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
      </div>
      <span class="fonts-col__heading-4"><b> Entity Details </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Entity Short Name</label>
            <input motifInput type="text" disabled aria-label="Entity Short Name Form Field"
              formControlName="entityshortname" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Bank Code</label>
            <input motifInput type="text" disabled aria-label="Bank Code Form Field" formControlName="bankcode" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>EY Region</label>
            <input motifInput type="text" disabled aria-label="EY Region Form Field" formControlName="eyregion" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>IR Office</label>
            <input motifInput type="text" disabled aria-label="IR Office Form Field" formControlName="iroffice" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
        </div>
      </div>
      <span class="fonts-col__heading-4"><b> Roles and Functions </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Engagement Partner</label>
            <input motifInput type="text" disabled aria-label="Bank size Form Field"
              formControlName="regionalleadpartner" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Engagement Manager</label>
            <input motifInput type="text" disabled aria-label="Bank size Form Field"
              formControlName="assignedleadauditor" />
          </motif-form-field>
        </div>

        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>FINMA Lead Auditor</label>
            <input motifInput type="text" disabled aria-label="Bank size Form Field"
              formControlName="assignedFinmaleadauditor" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Internal Audit Engagement Leader</label>
            <input motifInput type="text" disabled aria-label="Bank size Form Field"
              formControlName="internalauditengagementleader" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Client Contacts</label>
            <input motifInput type="text" disabled aria-label="Client Contacts Form Field"
              formControlName="clientcontacts" />
          </motif-form-field>

        </div>

      </div>

      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeRP" disabled>Financial Audit -
                RP</motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeKP" disabled>Loan Audit - KP
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeAP" disabled>Regulatory Audit -
                AP</motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeOther" disabled>Other Audit - OT
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <div>
            <motif-form-field>
              <motif-checkbox formControlName="AuditTypeIA" disabled>Internal Audit - IR
              </motif-checkbox>
            </motif-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.AuditTypeRP">
        <span class="fonts-col__heading-4 AuditHeadingdiv"><b class="AuditHeading"> Financial Audit - RP </b></span>        
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Financial Audit (RP) Lead</label>
              <input motifInput type="text" disabled aria-label="Financial Audit (RP) Lead"
                formControlName="financialauditleadRP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" disabled
                (dateChanged)="onChangeStartDateRP($event)" name="auditperiodstartdateRP" id="datepicker1"
                #dp2="motifDatepicker" (keydown.enter)="dp2.openCalendar()" (click)="dp2.toggleCalendar()"
                formControlName="auditperiodstartdateRP" />

            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled [options]="endDateRPOptions" 
                name="auditperiodenddateRP" id="datepicker2" #dp3="motifDatepicker" (keydown.enter)="dp3.openCalendar()"
                (click)="dp3.toggleCalendar()" formControlName="auditperiodenddateRP" />

            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" disabled
                (dateChanged)="onChangeExcutionStartDateRP($event)" name="auditexecutionstartdateRP" id="datepicker4"
                #dp4="motifDatepicker" (keydown.enter)="dp4.openCalendar()" (click)="dp4.toggleCalendar()"
                formControlName="auditexecutionstartdateRP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeExcutionEndDateRP($event)"
                placeholder="DD.MM.YYYY" [options]="executionEndDateRPOptions" disabled name="auditexecutionenddateRP"
                id="datepicker5" #dp5="motifDatepicker" (keydown.enter)="dp5.openCalendar()"
                (click)="dp5.toggleCalendar()" formControlName="auditexecutionenddateRP" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - RP</label>
              <input motifDatepicker class="motif-input" disabled (dateChanged)="onChangeIAworkCompletedDateRP($event)"
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="iaworkcompletedRP" id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Decisive Date</label>
              <input motifDatepicker class="motif-input" 
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="decisivedate" id="datepickerDD" #dpDD="motifDatepicker" (keydown.enter)="dpDD.openCalendar()"
                (click)="dpDD.toggleCalendar()" formControlName="decisivedate"/>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Latest Sign-off Date</label>
              <input motifDatepicker class="motif-input" 
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="latestSignoffDate" id="datepickerLSD" #dpLSD="motifDatepicker" (keydown.enter)="dpLSD.openCalendar()"
                (click)="dpLSD.toggleCalendar()" formControlName="latestSignoffDate"/>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>RP Status</label>
              <input motifInput type="text" disabled aria-label="RP Status Form Field" formControlName="statusidRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': isNeccessaryfield, 'showclass':!isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Actual Sign-off Date</label>
              <input motifDatepicker class="motif-input" 
                placeholder="DD.MM.YYYY" [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="actualSignoffDate" id="datepickerLSD" #dpASD="motifDatepicker" (keydown.enter)="dpASD.openCalendar()"
                (click)="dpASD.toggleCalendar()" formControlName="actualSignoffDate"/>
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - RP</label>
              <input motifInput type="text" disabled aria-label="Announcement delivery Form Field"
                formControlName="announcementdeliveryRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - RP</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - RP</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - RP</label>
              <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                formControlName="iaworkpaperreviewdueRP" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - RP</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - RP</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Final Report To Client Due - RP</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueRP" />
            </motif-form-field>
          </div>
          <div [ngClass]="{'hideclass': !isNeccessaryfield, 'showclass':isNeccessaryfield}" class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>RP Status</label>
              <input motifInput type="text" disabled aria-label="RP Status Form Field" formControlName="statusidRP" />
            </motif-form-field>
          </div>
        </div>
      <span class="fonts-col__heading-4"><b>AGM Details </b></span>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Meeting Format</label>
            <input motifInput formControlName="meetingFormat" [disabled] aria-label="Meeting Format" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Date</label>
            <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
              [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="agmDate" id="datepicker20"
              #dp20="motifDatepicker" (keydown.enter)="dp20.openCalendar()" (click)="dp20.toggleCalendar()"
              formControlName="agmDate" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>Start Time</label>
            <input motifInput type="text" formControlName="startTime" name="startTime" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-2">
          <motif-form-field>
            <label motifLabel>End Time</label>
            <input motifInput type="text" formControlName="endTime" name="endTime" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>Location</label>
            <input motifInput type="text" aria-label="Location" formControlName="agmLocation" />
          </motif-form-field>
        </div>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-lg-3">
          <motif-form-field>
            <label motifLabel>EY Representative</label>
            <input motifInput type="text" aria-label="EY Representative" formControlName="eyRepresentative" />
          </motif-form-field>
        </div>
        <div class="motif-col-xs-4 motif-col-lg-9">
          <motif-form-field>
            <label motifLabel>Notes</label>
            <input motifInput type="text" aria-label="Notes" formControlName="agmNotes" />
          </motif-form-field>
        </div>
      </div>
    </div>

      <div *ngIf="form.value.AuditTypeKP">
        <span class="fonts-col__heading-4 AuditHeadingdiv"><b class="AuditHeading">Loan Audit - KP </b></span>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Financial Audit (KP) Lead</label>
              <input motifInput type="text" disabled aria-label="Financial Audit (KP) Lead Form Field"
                formControlName="financialauditleadKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" disabled name="auditexecutionstartdateKP"
                id="datepicker6" #dp6="motifDatepicker" (keydown.enter)="dp6.openCalendar()"
                (click)="dp6.toggleCalendar()" formControlName="auditexecutionstartdateKP"
                (dateChanged)="onChangeExcutionStartDateKP($event)" />

            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Execution End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                (dateChanged)="onChangeExcutionEndDateKP($event)" [options]="executionEndDateKPOptions"
                name="auditexecutionenddateKP" id="datepicker7" #dp7="motifDatepicker"
                (keydown.enter)="dp7.openCalendar()" (click)="dp7.toggleCalendar()"
                formControlName="auditexecutionenddateKP" />

            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Completed - KP</label>
              <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateKP($event)"
                placeholder="DD.MM.YYYY" disabled [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                name="iaworkcompletedKP" id="datepickerIA" #dpIA="motifDatepicker" (keydown.enter)="dpIA.openCalendar()"
                (click)="dpIA.toggleCalendar()" formControlName="iaworkcompletedKP" />

            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Announcement Delivery - KP</label>
              <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                formControlName="announcementdeliveryKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Data Due - KP</label>
              <input motifInput type="text" disabled aria-label="Client due date Form Field"
                formControlName="clientdatadueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Draft Report - KP</label>
              <input motifInput type="text" disabled aria-label="Draft report Form Field"
                formControlName="draftreportKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>IA Work Paper Review Due - KP</label>
              <input motifInput type="text" disabled aria-label="IA Work PaPer Review Due  Form Field"
                formControlName="iaworkpaperreviewdueKP" />
            </motif-form-field>
          </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Draft Report To Client Due - KP</label>
              <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                formControlName="draftreporttoclientdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Client Feedback Due - KP</label>
              <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                formControlName="clientfeedbackdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Final Report To Client Due - KP</label>
              <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                formControlName="finalreporttoclientdueKP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>KP Status</label>
              <input motifInput type="text" disabled aria-label="KP Status Form Field" formControlName="statusidKP" />
            </motif-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.AuditTypeAP">
        <span class="fonts-col__heading-4 AuditHeadingdiv"><b class="AuditHeading"> Regulatory Audit - AP </b></span>
        <div class="motif-row">
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Regulatory Audit (AP) Lead</label>
              <input motifInput type="text" disabled aria-label="Regulatory Audit (AP) Lead Form Field"
                formControlName="financialauditleadAP" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period Start Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateAP" id="datepicker8"
                #dp8="motifDatepicker" (keydown.enter)="dp8.openCalendar()" (click)="dp8.toggleCalendar()"
                formControlName="auditperiodstartdateAP" (dateChanged)="onChangeStartDateAP($event)" />
            </motif-form-field>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-2">
            <motif-form-field>
              <label motifLabel>Audit Period End Date</label>
              <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                [options]="endDateAPOptions" name="auditperiodenddateAP" id="datepicker9" #dp9="motifDatepicker"
                (keydown.enter)="dp9.openCalendar()" (click)="dp9.toggleCalendar()"
                formControlName="auditperiodenddateAP" />
              <motif-error [showIcon]="false" *ngIf="
                      form.get('auditperiodenddateAP').hasError('required') &&
                      form.get('auditperiodenddateAP').touched
                      ">Please select a audit period end date!</motif-error>
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit Execution Start Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateAP"
                  id="datepicker10" #dp10="motifDatepicker" (keydown.enter)="dp10.openCalendar()"
                  (click)="dp10.toggleCalendar()" formControlName="auditexecutionstartdateAP"
                  (dateChanged)="onChangeExcutionStartDateAP($event)" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit Execution End Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  (dateChanged)="onChangeExcutionEndDateAP($event)" [options]="executionEndDateAPOptions" 
                  name="auditexecutionenddateAP" id="datepicker11" #dp11="motifDatepicker"
                  (keydown.enter)="dp11.openCalendar()" (click)="dp11.toggleCalendar()"
                  formControlName="auditexecutionenddateAP" />
              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Work Completed - AP</label>
                <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateAP($event)"
                  placeholder="DD.MM.YYYY" disabled [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                  name="iaworkcompletedAP" id="datepickerIA" #dpIA="motifDatepicker"
                  (keydown.enter)="dpIA.openCalendar()" (click)="dpIA.toggleCalendar()"
                  formControlName="iaworkcompletedAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Announcement Delivery - AP</label>
                <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                  formControlName="announcementdeliveryAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Client Data Due - AP</label>
                <input motifInput type="text" disabled aria-label="Client due date Form Field"
                  formControlName="clientdatadueAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Draft Report - AP</label>
                <input motifInput type="text" disabled aria-label="Draft report Form Field"
                  formControlName="draftreportAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Work Paper Review Due - AP</label>
                <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                  formControlName="iaworkpaperreviewdueAP" />
              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Draft Report To Client Due - AP</label>
                <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                  formControlName="draftreporttoclientdueAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Client Feedback Due - AP</label>
                <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                  formControlName="clientfeedbackdueAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Final Report To Client Due - AP</label>
                <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                  formControlName="finalreporttoclientdueAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>AP Status</label>
                <input motifInput type="text" disabled aria-label="AP Status Form Field" formControlName="statusidAP" />
              </motif-form-field>
            </div>
          </div>
          <div class="motif-col-xs-4 motif-col-lg-3">
            <motif-form-field>
              <label motifLabel>Reg. Audit Co-Sign</label>
              <input motifInput type="text" disabled aria-label="Reg. Audit Co-Sign" formControlName="regAuditCoSign" />
            </motif-form-field>
          </div>
          
      </div>
      <div *ngIf="form.value.AuditTypeOther">
          <span class="fonts-col__heading-4 AuditHeadingdiv"><b class="AuditHeading">Other Audit - OT </b></span>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Other Audit Lead</label>
                <input motifInput type="text" disabled aria-label="Other Audit Lead Form Field"
                  formControlName="financialauditleadOther" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit Period Start Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditperiodstartdateOther"
                  id="datepicker12" #dp12="motifDatepicker" (keydown.enter)="dp12.openCalendar()"
                  (click)="dp12.toggleCalendar()" formControlName="auditperiodstartdateOther"
                  (dateChanged)="onChangeStartDateOT($event)" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit Period End Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  [options]="endDateOTOptions" name="auditperiodenddateOther" id="datepicker13" #dp13="motifDatepicker"
                  (keydown.enter)="dp13.openCalendar()" (click)="dp13.toggleCalendar()"
                  formControlName="auditperiodenddateOther" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit Execution Start Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="auditexecutionstartdateOther"
                  id="datepicker14" #dp14="motifDatepicker" (keydown.enter)="dp14.openCalendar()"
                  (click)="dp14.toggleCalendar()" formControlName="auditexecutionstartdateOther"
                  (dateChanged)="onChangeExcutionStartDateOT($event)" />

              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit Execution End Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" disabled
                  [options]="executionEndDateOTOptions" name="auditexecutionenddateAP" id="datepicker15"
                  #dp15="motifDatepicker" (keydown.enter)="dp15.openCalendar()" (click)="dp15.toggleCalendar()"
                  formControlName="auditexecutionenddateOther" (dateChanged)="onChangeExcutionEndDateOT($event)" />

              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Work Completed - OT</label>
                <input motifDatepicker class="motif-input" (dateChanged)="onChangeIAworkCompletedDateOT($event)"
                  placeholder="DD.MM.YYYY" disabled [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}"
                  name="iaworkcompletedOT" id="datepickerIA" #dpIA="motifDatepicker"
                  (keydown.enter)="dpIA.openCalendar()" (click)="dpIA.toggleCalendar()"
                  formControlName="iaworkcompletedOT" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Announcement Delivery - OT</label>
                <input motifInput type="text" disabled aria-label="Announcemrnt delivery Form Field"
                  formControlName="announcementdeliveryOT" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Client Data Due - OT</label>
                <input motifInput type="text" disabled aria-label="Client due date Form Field"
                  formControlName="clientdatadueOT" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Draft Report - OT</label>
                <input motifInput type="text" disabled aria-label="Draft report Form Field"
                  formControlName="draftreportOT" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Work Paper Review Due - OT</label>
                <input motifInput type="text" disabled aria-label="IA Work Paper Review Due  Form Field"
                  formControlName="iaworkpaperreviewdueOT" />
              </motif-form-field>
            </div>
          </div>
            <div class="motif-row">
              <div class="motif-col-xs-4 motif-col-lg-3">
                <motif-form-field>
                  <label motifLabel>Draft Report To Client Due - OT</label>
                  <input motifInput type="text" disabled aria-label="Draft Report To Client Due Form Field"
                    formControlName="draftreporttoclientdueOT" />
                </motif-form-field>
              </div>
              <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                  <label motifLabel>Client Feedback Due - OT</label>
                  <input motifInput type="text" disabled aria-label="Client feedback due Form Field"
                    formControlName="clientfeedbackdueOT" />
                </motif-form-field>
              </div>
              <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                  <label motifLabel>Final Report To Client Due - OT</label>
                  <input motifInput type="text" disabled aria-label="Final Report To Client Due Form Field"
                    formControlName="finalreporttoclientdueOT" />
                </motif-form-field>
              </div>
              <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                  <label motifLabel>OT Status</label>
                  <input motifInput type="text" disabled aria-label="OT Status Form Field"
                    formControlName="statusidOT" />
                </motif-form-field>
              </div>
            </div>
            <div class="motif-row">
              <div class="motif-col-xs-4 motif-col-lg-3">
                <motif-form-field>
                  <label motifLabel>OT Partner</label>
                  <input motifInput type="text" disabled aria-label="Regional Lead Partner Form Field"
                    formControlName="regionalleadpartnerOther" />
                </motif-form-field>
              </div>
              <div class="motif-col-xs-4 motif-col-lg-2">
                <motif-form-field>
                  <label motifLabel>OT Engagement Manager</label>
                  <input motifInput type="text" disabled aria-label="Assigned Lead Auditor Form Field"
                    formControlName="assignedleadauditorOther" />
                </motif-form-field>
              </div>
            </div>
        </div>
        <div class="motif-row">
          <div class="motif-col-xs-4">
            <motif-form-field>
              <label motifLabel>Comment</label>
              <textarea motifInput aria-label=" example textarea" class="motif-textarea" disabled
                formControlName="Comment"></textarea>
            </motif-form-field>
          </div>
        </div>
        <div *ngIf="form.value.AuditTypeIA">
          <span class="fonts-col__heading-4 AuditHeadingdiv"><b class="AuditHeading"> Internal Audit - IR </b></span>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Audit 1</label>
                <input motifInput type="text" formControlName="internalAudit1"
                  [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                  class="ClienentitytModalselect" aria-label="IA Audit 1" />
                <motif-error *ngIf="form.get('internalAudit1').hasError('required') && form.get('internalAudit1').touched
                            ">Please Enter Internal Audit 1</motif-error>
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit 1 Execution Start Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                  [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ia1periodstartdate" id="datepicker16"
                  #dp16="motifDatepicker" (keydown.enter)="dp16.openCalendar()" (click)="dp16.toggleCalendar()"
                  formControlName="ia1periodstartdate" (dateChanged)="onChangeStartDateIR1($event)" />

                <motif-error [showIcon]="false" *ngIf="
                          form.get('ia1periodstartdate').hasError('required') &&
                          form.get('ia1periodstartdate').touched
                          ">Please select a audit period start date!</motif-error>
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit 1 Execution End Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="endDateIR1Options" 
                  name="ia1periodenddate" id="datepicker17" #dp17="motifDatepicker"
                  (keydown.enter)="dp17.openCalendar()" (click)="dp17.toggleCalendar()"
                  formControlName="ia1periodenddate" />
                <motif-error [showIcon]="false" *ngIf="
                          form.get('ia1periodenddate').hasError('required') &&
                          form.get('ia1periodenddate').touched
                          ">Please select a audit period end date!</motif-error>
              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>IA Audit 2</label>
                <input motifInput type="text" formControlName="internalAudit2"
                  [disabled]="RegionBasedENabledFlag==true?true: advanceUpdate ==true?true:false"
                  class="ClienentitytModalselect" aria-label="IA Audit 2" />
                <motif-error *ngIf="form.get('internalAudit2').hasError('required') && form.get('internalAudit2').touched
                            ">Please Enter Internal Audit 2</motif-error>
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit 2 Execution Start Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY"
                  [options]="{dateRange: false, dateFormat: 'dd.mm.yyyy',firstDayOfWeek:'mo'}" name="ia2periodstartdate" id="datepicker18"
                  #dp18="motifDatepicker" (keydown.enter)="dp18.openCalendar()" (click)="dp18.toggleCalendar()"
                  formControlName="ia2periodstartdate" (dateChanged)="onChangeStartDateIR2($event)" />

                <motif-error [showIcon]="false" *ngIf="
                          form.get('ia2periodstartdate').hasError('required') &&
                          form.get('ia2periodstartdate').touched
                          ">Please select a audit period start date!</motif-error>
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit 2 Execution End Date</label>
                <input motifDatepicker class="motif-input" placeholder="DD.MM.YYYY" [options]="endDateIR2Options" 
                  name="ia2periodenddate" id="datepicker19" #dp19="motifDatepicker"
                  (keydown.enter)="dp19.openCalendar()" (click)="dp19.toggleCalendar()"
                  formControlName="ia2periodenddate" />
                <motif-error [showIcon]="false" *ngIf="
                          form.get('ia2periodenddate').hasError('required') &&
                          form.get('ia2periodenddate').touched
                          ">Please select a audit period end date!</motif-error>
              </motif-form-field>
            </div>
          </div>
        </div>
      <motif-accordion id="collapseSection">
        <motif-accordion-header>
          Additional details
        </motif-accordion-header>
        <motif-accordion-content>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Reporting Language</label>
                <input motifInput type="text" disabled aria-label="Reporting language Form Field"
                  formControlName="reportinglanguage" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Bank Size</label>
                <input motifInput type="text" disabled aria-label="Bank size Form Field" formControlName="banksize" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit ID Code - RP</label>
                <input motifInput type="text" disabled aria-label="RP ID Code Field" formControlName="idcodeRP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit ID Code - KP</label>
                <input motifInput type="text" disabled aria-label="KP ID Code Field" formControlName="idcodeKP" />
              </motif-form-field>
            </div>
          </div>
          <div class="motif-row">
            <div class="motif-col-xs-4 motif-col-lg-3">
              <motif-form-field>
                <label motifLabel>Audit ID Code - AP</label>
                <input motifInput type="text" disabled aria-label="AP ID Code Field" formControlName="idcodeAP" />
              </motif-form-field>
            </div>
            <div class="motif-col-xs-4 motif-col-lg-2">
              <motif-form-field>
                <label motifLabel>Audit ID Code - OT</label>
                <input motifInput type="text" disabled aria-label="Other ID Code Field" formControlName="idcodeOther" />
              </motif-form-field>
            </div>
          </div>
        </motif-accordion-content>
      </motif-accordion>
    </fieldset>
  </form>
  <div class="motif-row">
    <div class="motif-col-xs-4 motif-col-lg-10"></div>
    <div class="motif-col-xs-4 motif-col-lg-1">

    </div>
    <div class="motif-col-xs-4 motif-col-lg-1">
      <button motifButton id="btnCancelEmployee" (click)="oncancel('cancel')" color="secondary">Cancel</button>
    </div>

  </div>

  <motif-modal [show]="Escmodalflag" (close)="Escmodalflag = false">
    <motif-modal-header>
        Confirmation

    </motif-modal-header>
    <motif-modal-body>
      <h1>All changes made will be discarded. Are you sure you want to close the form? </h1>
    </motif-modal-body>
    <motif-modal-footer>
        <button motifButton color="primary" (click)="oncancel('cancel')" type="submit">Yes</button>
       <button motifButton color="secondary" (click)="Escmodalflag = false">No</button>
    </motif-modal-footer>
  </motif-modal>
</div>
