import { CellRendorsComponent } from './Cell-Rendors/Cell-Rendors/Cell-Rendors.component';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ColDef, ColGroupDef, ICellRendererParams, ITooltipParams } from 'ag-grid-community';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { AggridganttchartService } from './aggridganttchart.service';
import { AuditAssignmentService } from '../../AuditAssignment/audit-assignment/audit-assignment.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MyLinkRendererComponent } from 'src/app/start-up/table-link-render-component';
import { LowerCasePipe } from '@angular/common';



@Component({
  selector: 'app-AG-Grid-GanttChart',
  templateUrl: './AG-Grid-GanttChart.component.html',
  styleUrls: ['./AG-Grid-GanttChart.component.scss']
})
export class AGGridGanttChartComponent implements OnInit {

  constructor(private lowerCasePipe: LowerCasePipe, private datePipe: DatePipe, public ganttservice: AggridganttchartService, private _auditAssignmentService: AuditAssignmentService, private router: Router) { }

  public selectedYear: any;
  public currentmonth:any;
  public lstYear: any = [];
  public TrackerViewData: any = [];
  public originalTrackerViewData:any=[];
  public mondayDates: string[] = [];
  public frameworkComponents: any;
  public TrackerViewMaingridcolDef: any[] = [];
  public modules = AllCommunityModules;
  public gridApi: any;
  public ShowModel: boolean = false;
  public popupFormTitle: any;
  public AuditTypecolDef: any[] = [];
  public AuditTypeRowData = [];
  public Engagementpartner: any;
  public EngagementManager: any;
  public internalauditengagementleader: any;
  public Finmaleadauditor: any;
  public OTPartner: any;
  public OTManager: any;
  public NgSearchFlag: boolean = true;
  public selectedAuditType: any = "";
  public selectedLead: any = "";
  public lstAuditTypeAP: any;
  public lstAuditTypeKP: any;
  public lstAuditTypeRP: any;
  public lstAuditTypeOT: any;
  public eyRegionMasterData: any;
  public irRBofficeMasterData: any;
  public selectedAuditStatus: any;
  public selectedRegion: any;
  public selectedIRoffice: any;
  public lstEYContacts: any;
  public selectedPartner: any;
  public selectedManager: any;
  public AuditAssignmentID: any;
  ngOnInit() {
    this.GenerateYear();
    //this.selectedYear = new Date().getFullYear();
    // this.currentmonth=new Date().getMonth();
    // if (this.currentmonth<=2){
    //   this.selectedYear=this.selectedYear-1;
    // }
    //this.GetTrackerView(this.selectedYear);
    //this.GenerateMondaydatesFromSelectedYear(this.selectedYear);
    this.setFrameworkoptions();
    this.GetMastersData();

    var yearData = this._auditAssignmentService.getData();
         if (yearData["AuditYear"] != ""){
           this.selectedYear = yearData["AuditYear"];
          }
         else{
          this.currentmonth=new Date().getMonth();
          this.selectedYear = new Date().getFullYear();
         }
         if (this.currentmonth<=2){
          this.selectedYear=this.selectedYear-1;
          this.GetTrackerView(this.selectedYear);
          this.GenerateMondaydatesFromSelectedYear(this.selectedYear);
         }
         else{
          this.GetTrackerView(this.selectedYear);
          this.GenerateMondaydatesFromSelectedYear(this.selectedYear);
         }
         
    //  this.createColDef();
    //  this.setDivHeight(); 

  }
  GetMastersData() {
    this.ganttservice.getMastersData()
      .subscribe(
        res => {
          this.lstAuditTypeAP = res[0];
          this.lstAuditTypeKP = res[1];
          this.lstAuditTypeRP = res[2];
          this.lstAuditTypeOT = res[3];
          this.eyRegionMasterData = res[4];
          this.irRBofficeMasterData = res[5];
          this.lstEYContacts = res[6];
        },
        err => {
          console.log(err);
        }
      )
  }
  GenerateYear() {
    var year = new Date().getFullYear();
    year = 2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
  }

  public GetTrackerView(year) {
    this.ganttservice.GetTrackerView(year, this.selectedAuditType, this.selectedLead).subscribe(res => {
      this.TrackerViewData = res;
      this.originalTrackerViewData= this.TrackerViewData;

    })
  }

  public GenerateMondaydatesFromSelectedYear(year) {
    this.mondayDates = [];
    let years = [year, year + 1];
    years.forEach(element => {
      var days: any = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
      for (let month = 1; month <= 12; ++month) {
        let y = element, dy = 'mon';
        var date: Date = new Date(y + '/' + month + '/1');
        var currentmonth: number = month;
        var firstday: boolean = false;
        while (currentmonth === month) {
          firstday = date.getDay() === days[dy] || firstday;
          if (firstday)
            this.mondayDates = [...this.mondayDates, y + '-' + currentmonth + '-' + date.getDate()];
          date.setDate(date.getDate() + (firstday ? 7 : 1));
          currentmonth = date.getMonth() + 1;
        }

      }
    });
    this.createTrakerViewColDef()

  }

  setFrameworkoptions() {
    this.frameworkComponents = {
      PopUpAction: CellRendorsComponent,
      linkActions: MyLinkRendererComponent,
    }
  }
  ExpandTrackerView(e) {
    this.AuditTypecolDef = []
    this.ShowModel = true;
    this.AuditAssignmentID = e.rowData.auditAssignmentId
    this.Engagementpartner = e.rowData.engagementPartner;
    this.EngagementManager = e.rowData.engagementManager;
    this.Finmaleadauditor = e.rowData.finmaLeadAuditor;
    this.internalauditengagementleader = e.rowData.internalAuditEngagementLeader;
    this.OTPartner = e.rowData.otPartner
    this.OTManager = e.rowData.otManager
    this.popupFormTitle = e.rowData.formTitle;
    let ap = [];
    let kp = [];
    let ot = [];
    let rp = [];
    let data = e.rowData.allAuditypeDate;
    Object.keys(data).forEach((keyName) => {
      if (keyName.startsWith('ap')) {
        ap = [...ap, [keyName, data[keyName]]];
      } else if (keyName.startsWith('kp')) {
        kp = [...kp, [keyName, data[keyName]]];
      } else if (keyName.startsWith('ot')) {
        ot = [...ot, [keyName, data[keyName]]];
      } else if (keyName.startsWith('rp') || keyName.startsWith('agm')) {
        rp = [...rp, [keyName, data[keyName]]];
      }
    });
    this.AuditTypeRowData = [
      {
        AuditType: 'Regulatory Audit AP', date: (Object as any).fromEntries(ap), Status: data.apStatusName, Lead: e.rowData.apFinancialAuditLead, draftReporttoClientDue: data.apDraftReportToClientDue
        , FinalReportToClientDue: data.apFinalReportToClientDue
      },
      {
        AuditType: 'Loan Audit KP', date: (Object as any).fromEntries(kp), Status: data.kpStatusName, Lead: e.rowData.kpFinancialAuditLead, draftReporttoClientDue: data.kpDraftReportToClientDue
        , FinalReportToClientDue: data.kpFinalReportToClientDue
      },
      {
        AuditType: 'Financial Audit RP', date: (Object as any).fromEntries(rp), Status: data.rpStatusName, Lead: e.rowData.rpFinancialAuditLead, draftReporttoClientDue: data.rpDraftReportToClientDue
        , FinalReportToClientDue: data.rpFinalReportToClientDue
      },
      {
        AuditType: 'Other Audit OT', date: (Object as any).fromEntries(ot), Status: data.otStatusName, Lead: e.rowData.otFinancialAuditLead, draftReporttoClientDue: data.otDraftReportToClientDue
        , FinalReportToClientDue: data.otFinalReportToClientDue
      }]
    this.AuditTypeRowData = this.AuditTypeRowData.filter(x => x.Lead != null);
    let colDef: ColDef | ColGroupDef = {
      headerName: 'Audit Type',
      children: [
        { field: 'AuditType', headerName: 'Audit', resizable: true, pinned: 'left', width: 130, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'closed' },
        { field: 'Lead', headerName: 'Lead', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'closed' },
        { field: 'AuditType', headerName: 'Audit', resizable: true, pinned: 'left', width: 130, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'Lead', headerName: 'Lead', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'Status', headerName: 'Status', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        {
          field: 'draftReporttoClientDue', headerName: 'Draft Report to Client Due', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open'
          , cellRenderer: (data) => {
            if (data.value != undefined)
              return moment(data.value).format('DD.MM.YYYY')
          }
        },
        {
          field: 'FinalReportToClientDue', headerName: 'Final Report To Client Due ', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open',
          cellRenderer: (data) => {
            if (data.value != undefined)
              return moment(data.value).format('DD.MM.YYYY')
          }
        }
      ]
    }
    this.AuditTypecolDef = [...this.AuditTypecolDef, colDef];

    let years = [this.selectedYear, this.selectedYear + 1];
    years.forEach(y => {
      
      let Yearchildren: any = [], Monthchildren: any = [];
      let month = 1, monthchild = {}, monthName = ""
      let mondays = this.mondayDates.filter(x => this.datePipe.transform(new Date(x), 'y') == y);
      mondays.forEach(dateValue => {
        
        if (Monthchildren.length == 0)
          monthName = this.datePipe.transform(dateValue, 'MMM');
        if (month != Number(this.datePipe.transform(dateValue, 'M'))) {
          let child = {
            headerName: '' + monthName as string,
            children: Monthchildren
          }
          Yearchildren.push(child);
          month = Number(this.datePipe.transform(dateValue, 'M'));
          monthName = monthName = this.datePipe.transform(dateValue, 'MMM');
          Monthchildren = [];
        }
        monthchild = {
          field: 'date', width: 40, headerName: (this.datePipe.transform(dateValue, 'd') as string),
          tooltipValueGetter: params => {
            
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')) {
              return 'RP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.rpFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.kpStatusName == "" || params.value.kpStatusName == undefined ? "Not Started" : params.value.kpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')) {
              return 'KP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.kpFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            //if (params.data.AuditType.indexOf('AP') != -1 && params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.apStatusName == "" || params.value.apStatusName == undefined ? "Not Started" : params.value.apStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w'))
           //   && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')) {
           //   return 'AP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.apFinalReportToClientDue, 'MMM d')) + ')' as string;
           // }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.otStatusName == "" || params.value.otStatusName == undefined ? "Not Started" : params.value.otStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')) {
              return 'OT - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.otFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'y')) {
              return 'RP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.rpDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'y')) {
              return 'KP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.kpDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'y')) {
              return 'AP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.apDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'y')) {
              return 'OT - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.otDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'y')) {
              return 'RP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.rpIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'y')) {
              return 'KP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.kpIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'y')) {
              return 'AP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.apIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'y')) {
              return 'OT - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.otIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpDecisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'y')) {
            return 'RP - Decisive Date ' + '(' + (this.datePipe.transform(params.value.rpDecisiveDate, 'MMM d')) + ')' as string;
          }
          if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
          && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')) {
          return 'RP - Latest Sign-off Date ' + '(' + (this.datePipe.transform(params.value.rpLatestSignoffDate, 'MMM d')) + ')' as string;
        }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.apStatusName == "" || params.value.apStatusName == undefined ? "Not Started" : params.value.apStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w'))
             && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')) {
              return 'AP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.apFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')))
            && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y'))) {
            return 'KP  Final Feedback to Client Due ' + '(' + (this.datePipe.transform(params.value.kpClientFeedbackDue, 'MMM d') + '-' + this.datePipe.transform(params.value.kpClientFeedbackDue, 'MMM d') + ')') as string;
          }
        
            // console.log(params);
            // if (params.data.AuditType.indexOf('RP') != -1 && params.value.decisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.decisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            //   && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.decisiveDate), 'y')) {
            //   return 'Decisive Date ' + '(' + (this.datePipe.transform(params.value.decisiveDate, 'MMM d')) + ')' as string;
            // }
            
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return 'RP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.rpAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.rpAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {

              return 'AP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.apAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.apAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return 'KP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.kpAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.kpAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return 'OT - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.otAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.otAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }

            if ((params.value.agmDate != null && params.value.agmDate != '0001-01-01T00:00:00') && (Number(this.datePipe.transform(new Date(params.value.agmDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.agmDate), 'y')) {
              return 'AGM Date ' + '(' + (this.datePipe.transform(params.value.agmDate, 'MMM d')) + ')' as string;
            }
            if ((Number(this.datePipe.transform(new Date(), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))&& this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return 'Today ' + '(' + (this.datePipe.transform(new Date(), 'MMM d')) + ')' as string;
            }
            return '';
          },
          cellRenderer: () => {
            return '';
          },
          cellStyle: params => {
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.rpStatusName == "" || params.value.rpStatusName == undefined ? "Not Started" : params.value.rpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.kpStatusName == "" || params.value.kpStatusName == undefined ? "Not Started" : params.value.kpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.apStatusName == "" || params.value.apStatusName == undefined ? "Not Started" : params.value.apStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.otStatusName == "" || params.value.otStatusName == undefined ? "Not Started" : params.value.otStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.kpClientFeedbackDue == "" || params.value.kpClientFeedbackDue == undefined ? "Not Started" : params.value.kpClientFeedbackDue).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w'))
            && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y')
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
            return {
              backgroundColor: '#000000'
            };
          }
             if (params.data.AuditType.indexOf('AP') != -1 && params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
               && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')) {
              return {
                 backgroundColor: '#ff3300'
               };
             }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpDecisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'y')) {
            return {
              backgroundColor: '#9966FF'
            };
          }
          if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
          && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')) {
          return {
            backgroundColor: '#000000'
          };
        }
        if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.rpLatestSignoffDate == "" || params.value.rpLatestSignoffDate == undefined ? "Not Started" : params.value.rpLatestSignoffDate).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w'))
        && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')
        && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
        return {
          backgroundColor: '#ff3300'
        };
      }
      
            // if (params.data.AuditType.indexOf('RP') != -1 && params.value.decisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.decisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            //   && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.decisiveDate), 'y')) {
            //   return {
            //     backgroundColor: '#9966FF'
            //   };
            // }

            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.rpFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.kpFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.apFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.otFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.data.AuditType.indexOf('RP') != -1 && params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.rpFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.kpFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.data.AuditType.indexOf('AP') != -1 && params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.apFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.data.AuditType.indexOf('OT') != -1 && params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.otFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if ((params.value.agmDate != null && params.value.agmDate != '0001-01-01T00:00:00') && (Number(this.datePipe.transform(new Date(params.value.agmDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.agmDate), 'y')) {
              return {
                backgroundColor: '#7f7f7f'
              };
            }
            if ((Number(this.datePipe.transform(new Date(), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))&& this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#f2f2f2'
              };
            }
          }
        }

        Monthchildren.push(monthchild);


      })
      let yearchild = {
        headerName: monthName,
        children: Monthchildren
      }
      Yearchildren.push(yearchild);
      let DatecolDef = {
        headerName: '' + y as string,
        children: Yearchildren
      }
      this.AuditTypecolDef = [...this.AuditTypecolDef, DatecolDef];

    })


  }
  gotoAuditAssignmentfromPopup() {

    var obj = {
      auditassignmentid: this.AuditAssignmentID,
      flag: "view",
      screen: "Tracker"
    }
    this._auditAssignmentService.setupdateData(obj);
    this.router.navigate(['/auditassignment']);

  }
  gotoAuditAssignment(e) {
    var obj = {
      auditassignmentid: e.rowData.auditAssignmentId,
      flag: "view",
      screen: "Tracker"
    }
    this._auditAssignmentService.setupdateData(obj);
    this.router.navigate(['/auditassignment']);

  }
  handleGridReady(params) {
    this.gridApi = params;
    this.gridApi.api.showLoadingOverlay()
    this.gridApi.api.hideOverlay();
  };
  createTrakerViewColDef(): void {
    this.TrackerViewMaingridcolDef = [];
    let colDefs: ColDef = {
      headerName: 'Expand',
      width: 70,
      cellRenderer: "PopUpAction",
      pinned: 'left',
      cellRendererParams: {
        ExpandTrackerView: this.ExpandTrackerView.bind(this),
      },
    }
    this.TrackerViewMaingridcolDef = [...this.TrackerViewMaingridcolDef, colDefs]

    let colDef: ColDef | ColGroupDef = {
      headerName: "Audit Assignment",
      children: [
        {
          headerName: 'Bank Code', field: 'bankCodeRgrCid', sortable: true, unSortIcon: true, autoHeight: true, resizable: true, width: 60, pinned: 'left',
          cellRenderer: "linkActions",
          cellRendererParams: {
            onClick: this.gotoAuditAssignment.bind(this),

          },
          columnGroupShow: 'closed'
        },
        { field: 'clintentityShortNames', headerName: 'Entity Short Name', resizable: true, pinned: 'left', width: 100, columnGroupShow: 'closed' },
        {
          headerName: 'Bank Code', field: 'bankCodeRgrCid', sortable: true, unSortIcon: true, filter: true, autoHeight: true, resizable: true, width: 80, pinned: 'left',
          cellRenderer: "linkActions",
          cellRendererParams: {
            onClick: this.gotoAuditAssignment.bind(this),

          },
          columnGroupShow: 'open'
        },
        { field: 'clintentityShortNames', headerName: 'Entity Short Name', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'classification', headerName: 'Classification', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'apFinancialAuditLead', headerName: 'AP Lead', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'kpFinancialAuditLead', headerName: 'KP Lead', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'rpFinancialAuditLead', headerName: 'RP Lead', resizable: true, pinned: 'left', width: 100, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'eyregionName', headerName: 'EY Region', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'irofficeName', headerName: 'IR Office', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'allAuditypeDate.apStatusName', headerName: 'AP Status', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'allAuditypeDate.kpStatusName', headerName: 'KP Status', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'allAuditypeDate.rpStatusName', headerName: 'RP Status', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'engagementPartner', headerName: 'Engagement Partner', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'engagementManager', headerName: 'Engagement Manager', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'finmaLeadAuditor', headerName: 'FINMA Lead Auditor', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        { field: 'internalAuditEngagementLeader', headerName: 'Internal Audit Engagement Lead', resizable: true, pinned: 'left', width: 120, sortable: true, unSortIcon: true, filter: true, columnGroupShow: 'open' },
        
      ]
    }
    this.TrackerViewMaingridcolDef = [...this.TrackerViewMaingridcolDef, colDef];

    let years = [this.selectedYear, this.selectedYear + 1];
    years.forEach(y => {
      
      let Yearchildren: any = [], Monthchildren: any = [];
      let month = 1, monthchild = {}, monthName = ""
      let mondays = this.mondayDates.filter(x => this.datePipe.transform(new Date(x), 'y') == y);
      mondays.forEach(dateValue => {    
        if (Monthchildren.length == 0)
          monthName = this.datePipe.transform(dateValue, 'MMM');
        if (month != Number(this.datePipe.transform(dateValue, 'M'))) {
          let child = {
            headerName: '' + monthName as string,
            children: Monthchildren
          }
          Yearchildren.push(child);
          month = Number(this.datePipe.transform(dateValue, 'M'));
          monthName = monthName = this.datePipe.transform(dateValue, 'MMM');
          Monthchildren = [];
        }
        monthchild = {
          field: 'allAuditypeDate', width: 40, headerName: (this.datePipe.transform(dateValue, 'd') as string), resizable: false,
          tooltipValueGetter: params => {
            if (params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')) {
              return 'RP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.rpFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.kpStatusName == "" || params.value.kpStatusName == undefined ? "Not Started" : params.value.kpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')) {
              return 'KP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.kpFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.apStatusName == "" || params.value.apStatusName == undefined ? "Not Started" : params.value.apStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')) {
              return 'AP - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.apFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.otStatusName == "" || params.value.otStatusName == undefined ? "Not Started" : params.value.otStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')) {
              return 'OT - Final Report to Client Due ' + '(' + (this.datePipe.transform(params.value.otFinalReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.rpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'y')) {
              return 'RP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.rpDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.kpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'y')) {
              return 'KP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.kpDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.apDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'y')) {
              return 'AP - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.apDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.otDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'y')) {
              return 'OT - Draft Report to Client Due ' + '(' + (this.datePipe.transform(params.value.otDraftReportToClientDue, 'MMM d')) + ')' as string;
            }
            if (params.value.rpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'y')) {
              return 'RP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.rpIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.value.kpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'y')) {
              return 'KP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.kpIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.value.apIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'y')) {
              return 'AP - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.apIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            if (params.value.otIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'y')) {
              return 'OT - IA Work Paper Review Due ' + '(' + (this.datePipe.transform(params.value.otIaworkPaperReviewDue, 'MMM d')) + ')' as string;
            }
            
            if (params.value.rpDecisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'y')) {
            return 'RP -Decisive Date ' + '(' + (this.datePipe.transform(params.value.rpDecisiveDate, 'MMM d')) + ')' as string;
          }
          if (params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
          && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')) {
          return 'RP - Latest Sign-off Date ' + '(' + (this.datePipe.transform(params.value.rpLatestSignoffDate, 'MMM d')) + ')' as string;
        }
          if (params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y')) {
            return 'KP - Final Feedback to Client Due' + '(' + (this.datePipe.transform(params.value.kpClientFeedbackDue, 'MMM d')) + ')' as string;
          }
          // if (params.data.AuditType.indexOf('KP') != -1 && params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')))
          //   && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y'))) {
          //   return 'KP - Final Feedback to Client Due ' + '(' + (this.datePipe.transform(params.value.kpClientFeedbackDue, 'MMM d') + '-' + this.datePipe.transform(params.value.kpClientFeedbackDue, 'MMM d') + ')') as string;
          // }
          //   // console.log(this.datePipe.transform(new Date(dateValue), 'y'));
            // console.log(this.datePipe.transform(new Date(params.value.DecisiveDate), 'y'))
            // if (params.value.decisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.DecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            //   && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.DecisiveDate), 'y')) {
            //   return 'Decisive Date' + '(' + (this.datePipe.transform(params.value.DecisiveDate, 'MMM d')) + ')' as string;
            // }
            
            if (params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return 'RP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.rpAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.rpAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {

              return 'AP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.apAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.apAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return 'KP - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.kpAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.kpAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }
            if (params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return 'OT - Audit Execution Start to End Date ' + '(' + (this.datePipe.transform(params.value.otAuditEXPeriodStartDate, 'MMM d') + '-' + this.datePipe.transform(params.value.otAuditEXPeriodEndDate, 'MMM d') + ')') as string;
            }

            if ((params.value.agmDate != null && params.value.agmDate != '0001-01-01T00:00:00') && (Number(this.datePipe.transform(new Date(params.value.agmDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.agmDate), 'y')) {
              return 'AGM Date ' + '(' + (this.datePipe.transform(params.value.agmDate, 'MMM d')) + ')' as string;
            }
            if ((Number(this.datePipe.transform(new Date(), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))&& this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return 'Today ' + '(' + (this.datePipe.transform(new Date(), 'MMM d')) + ')' as string;
            }
            return '';
          },
          cellRenderer: () => {
            return '';
          },
          cellStyle: params => {
            if (params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.rpStatusName == "" || params.value.rpStatusName == undefined ? "Not Started" : params.value.rpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#ff3300'
              };
            }
            if (params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.kpStatusName == "" || params.value.kpStatusName == undefined ? "Not Started" : params.value.kpStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#ff3300'
              };
            }
            if (params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.apStatusName == "" || params.value.apStatusName == undefined ? "Not Started" : params.value.apStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#ff3300'
              };
            }
            if (params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.otStatusName == "" || params.value.otStatusName == undefined ? "Not Started" : params.value.otStatusName).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w'))
              && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#ff3300'
              };
            }
            if (params.value.rpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.value.kpFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.value.apFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.value.otFinalReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otFinalReportToClientDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.value.rpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.value.kpDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.value.apDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.value.otDraftReportToClientDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otDraftReportToClientDue), 'y')) {
              return {
                backgroundColor: '#ff9900'
              };
            }
            if (params.value.rpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.value.kpIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.value.apIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            if (params.value.otIaworkPaperReviewDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otIaworkPaperReviewDue), 'y')) {
              return {
                backgroundColor: '#009999'
              };
            }
            // if (params.value.rpDecisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            //   && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'y')) {
            //   return {
            //     backgroundColor: '#9966FF'
            //   };
            // }
        
            if (params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.rpFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.kpFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.apFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.otFinancialAuditLead == "IR" && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#0099FF'
              };
            }
            if (params.value.rpDecisiveDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
            && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpDecisiveDate), 'y')) {
            return {
              backgroundColor: '#9966FF'
            };
          }
          if (params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
          && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')) {
          return {
            backgroundColor: '#000000'
          };
        }
        if (params.value.rpLatestSignoffDate != '0001-01-01T00:00:00' && this.lowerCasePipe.transform(params.value.rpLatestSignoffDate == "" || params.value.rpStatusName == undefined ? "Not Started" : params.value.rpLatestSignoffDate).indexOf('completed') == -1 && Number(this.datePipe.transform(new Date(), 'w')) >= Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w'))
        && Number(this.datePipe.transform(new Date(dateValue), 'w')) === Number(this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'w')) && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpLatestSignoffDate), 'y')
        && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
        return {
          backgroundColor: '#ff3300'
        };
      }
          if (params.value.kpClientFeedbackDue != '0001-01-01T00:00:00' && (Number(this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpClientFeedbackDue), 'y')) {
              return {
                backgroundColor: '#000000'
              };
            }
            if (params.value.rpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.rpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.rpFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.rpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.value.kpAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.kpAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.kpFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.kpAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.value.apAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.apAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.apFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.apAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if (params.value.otAuditEXPeriodStartDate != '0001-01-01T00:00:00' && params.value.otAuditEXPeriodEndDate != '0001-01-01T00:00:00' && params.value.otFinancialAuditLead == "EY" && (Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'w')) <= Number(this.datePipe.transform(new Date(dateValue), 'w'))) && (Number(this.datePipe.transform(new Date(dateValue), 'w')) <= Number(this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'w')))
              && (this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodStartDate), 'y') || this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.otAuditEXPeriodEndDate), 'y'))) {
              return {
                backgroundColor: '#ffe600'
              };
            }
            if ((params.value.agmDate != null && params.value.agmDate != '0001-01-01T00:00:00') && (Number(this.datePipe.transform(new Date(params.value.agmDate), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))
              && this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(params.value.agmDate), 'y')) {
              return {
                backgroundColor: '#7f7f7f'
              };
            }
            if ((Number(this.datePipe.transform(new Date(), 'w')) === Number(this.datePipe.transform(new Date(dateValue), 'w')))&& this.datePipe.transform(new Date(dateValue), 'y') == this.datePipe.transform(new Date(), 'y')) {
              return {
                backgroundColor: '#f2f2f2'
              };
            }
          }
        }
        Monthchildren.push(monthchild);


      })
      let yearchild = {
        headerName: monthName,
        children: Monthchildren
      }
      Yearchildren.push(yearchild);
      let DatecolDef = {
        headerName: '' + y as string,
        children: Yearchildren
      }
      this.TrackerViewMaingridcolDef = [...this.TrackerViewMaingridcolDef, DatecolDef];
    });
  }
  onChangeAuditType() {
    searchAudit = this.selectedAuditType;
    this.ganttservice.GetTrackerView(this.selectedYear, this.selectedAuditType, this.selectedLead).subscribe(res => {
      
      this.TrackerViewData = res;    

    })

  }
  isExternalFilterPresent() {
    return searchAuditStatus.length != 0 || searchRegion.length != 0 || searchIRoffice.length != 0
      || searchPartner.length > 0 || searchmanager.length > 0;
  }
  externalFilterChanged(newValue, type) {

    if (type == "Status") {
      searchAuditStatus = [];
      searchAuditStatus = newValue;
    }
    else if (type == "Region") {
      searchRegion = [];
      searchRegion = newValue;
    }
    else if (type == "IROffice") {
      searchIRoffice = [];
      searchIRoffice = newValue;
    }
    else if (type == "Partner") {
      searchPartner = [];
      newValue.value.forEach(element => {
        searchPartner.push(element['contactName']);
      });

    }
    else if (type == "Manager") {
      searchmanager = [];
      newValue.value.forEach(element => {
        searchmanager.push(element['contactName']);
      });
    }

    this.gridApi.api.onFilterChanged();

  }
  TrackerViewExternalFilter(node) {
    let flag = true;
    if (flag == true && searchAuditStatus.length > 0) {
      if (searchAudit == "AP" && searchAuditStatus.indexOf(node.data.allAuditypeDate.apStatusName) == -1)
        flag = false;
      if (searchAudit == "KP" && searchAuditStatus.indexOf(node.data.allAuditypeDate.kpStatusName) == -1)
        flag = false;
      if (searchAudit == "RP" && searchAuditStatus.indexOf(node.data.allAuditypeDate.rpStatusName) == -1)
        flag = false;
      if (searchAudit == "OT" && searchAuditStatus.indexOf(node.data.allAuditypeDate.otStatusName) == -1)
        flag = false;
    }
    if (flag == true && searchRegion.length > 0 && searchRegion.indexOf(node.data.eyregionName) == -1)
      flag = false;
    if (flag == true && searchIRoffice.length > 0 && searchIRoffice.indexOf(node.data.irofficeName) == -1)
      flag = false;
    if (flag == true && searchPartner.length > 0 && searchPartner.indexOf(node.data.engagementPartner) == -1)
      flag = false;
    if (flag == true && searchmanager.length > 0 && searchmanager.indexOf(node.data.engagementManager) == -1)
      flag = false;
    return flag;
  }
  resetFIlters() {
    this.NgSearchFlag = false;
    this.selectedRegion = [];
    this.selectedAuditStatus = [];
    this.selectedIRoffice = [];
    this.selectedPartner = [];
    this.selectedManager = [];
    this.selectedLead = "";
    this.selectedAuditType = ""
    searchRegion = [];
    searchAuditStatus = [];
    searchIRoffice = [];
    searchPartner = [];
    searchmanager = [];
    this.TrackerViewData=this.originalTrackerViewData;
    setTimeout(() => {
      this.NgSearchFlag = true;
    }, 50);
    this.gridApi.api.onFilterChanged();
  }

}

var searchRegion = [], searchAuditStatus = [], searchIRoffice = [], searchPartner = [], searchmanager = [], searchAudit = "";