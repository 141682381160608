<ul>
    <li *ngIf="params?.NewAuditTypeRP !=undefined"> {{params?.NewAuditTypeRP}} </li>    
    <li *ngIf="params?.NewAuditTypeOT !=undefined"> {{params?.NewAuditTypeOT}} </li>
    <li *ngIf="params?.NewAuditTypeAP !=undefined"> {{params?.NewAuditTypeAP}} </li>
    <li *ngIf="params?.NewAuditTypeKP !=undefined"> {{params?.NewAuditTypeKP}} </li>
    <li *ngIf="params?.NewAuditTypeIA !=undefined"> {{params?.NewAuditTypeIA}} </li>
    <li *ngIf="params?.RegAuditCoSign !=undefined"> Reg. Audit Co-Sign : {{params.RegAuditCoSign}} </li>
    <li *ngIf="params?.FinancialAuditLead !=undefined"> Financial Audit Lead : {{params.FinancialAuditLead}} </li>

    <li *ngIf="params?.AuditPeriodStartDate !=undefined"> Audit Period Start Date : {{params.AuditPeriodStartDate |
        momentPipe :'LL'}} </li>

    <li *ngIf="params?.AuditPeriodEndDate !=undefined"> Audit Period End Date : {{params.AuditPeriodEndDate | momentPipe
        :'LL'}} </li>
    
    <li *ngIf="params?.DecisiveDate !=undefined"> Decisive Date : {{params.DecisiveDate |
            momentPipe :'LL'}} </li>
    <li *ngIf="params?.LatestSignoffDate !=undefined"> Latest Sign-off Date : {{params.LatestSignoffDate |
            momentPipe :'LL'}} </li>
    <li *ngIf="params?.ActualSignoffDate !=undefined"> Actual Sign-off Date : {{params.ActualSignoffDate |
                    momentPipe :'LL'}} </li>

    <li
        *ngIf="params?.RegionalLeadPartnerId !=undefined?params?.auditType !=undefined?params?.auditType=='OT'?true:false:false:false">
        OT Partner : {{params.RegionalLeadPartnerId}} </li>


    <li
        *ngIf="params?.AssignedLeadAuditorId !=undefined?params?.auditType !=undefined ? params?.auditType=='OT'?true:false:false:false">
        OT Engagement Manager : {{params.AssignedLeadAuditorId}} </li>
    <!-- <li *ngIf="params?.InternalAuditEngagementLeaderId !=undefined"> Internal Audit Engagement Leader  : {{params.InternalAuditEngagementLeaderId}}  </li> -->

    <!-- <li *ngIf="params?.AssignedAuditTeamMembers !=undefined"> Assigned Audit Team Members : {{params.AssignedAuditTeamMembers}}  </li> -->

    <li *ngIf="params?.AuditExecutionStartDate !=undefined"> Audit Execution Start Date :
        {{params.AuditExecutionStartDate | momentPipe :'LL' }} </li>

    <li *ngIf="params?.AuditExecutionEndDate !=undefined"> Audit Execution End Date : {{params.AuditExecutionEndDate |
        momentPipe :'LL'}} </li>
    <li *ngIf="params?.AnnouncementDelivery !=undefined"> Announcement Delivery : {{params.AnnouncementDelivery |
        momentPipe :'LL'}}
    </li>
    <li *ngIf="params?.ClientDataDue !=undefined"> Client Data Due : {{params.ClientDataDue | momentPipe :'LL'}} </li>
    <li *ngIf="params?.DraftReport !=undefined"> Draft Report : {{params.DraftReport | momentPipe :'LL'}} </li>
    <li *ngIf="params?.IaworkCompleted !=undefined"> IA work Completed : {{params.IaworkCompleted | momentPipe :'LL'}}
    </li>
    <li *ngIf="params?.IaworkPaperReviewDue !=undefined"> IA work Paper Review Due : {{params.IaworkPaperReviewDue |
        momentPipe :'LL'}} </li>
    <li *ngIf="params?.DraftReportToClientDue !=undefined"> Draft Report To Client Due : {{params.DraftReportToClientDue
        | momentPipe :'LL'}} </li>
    <li *ngIf="params?.ClientFeedbackDue !=undefined"> Client Feed back Due : {{params.ClientFeedbackDue | momentPipe
        :'LL'}} </li>
    <li *ngIf="params?.FinalReportToClientDue !=undefined"> Final Report To Client Due : {{params.FinalReportToClientDue
        | momentPipe :'LL'}} </li>

    <li *ngIf="params?.StatusRpid !=undefined"> RP Status : {{params.StatusRpid}} </li>
    <li *ngIf="params?.StatusKpid !=undefined"> KP Status : {{params.StatusKpid}} </li>
    <li *ngIf="params?.StatusApid !=undefined"> AP Status : {{params.StatusApid}} </li>
    <li *ngIf="params?.StatusOtid !=undefined"> OT Status : {{params.StatusOtid}} </li>
    <li *ngIf="params?.MeetingFormatId !=undefined">
        Meeting Format : {{params.MeetingFormatId}} </li>
    <li *ngIf="params?.Date !=undefined">
        Date : {{params?.Date | momentPipe :'LL'}} </li>
    <li *ngIf="params?.StartTime !=undefined">
        Start Time : {{params.StartTime}} </li>
    <li *ngIf="params?.EndTime !=undefined">
        End Time : {{params.EndTime}} </li>
    <li *ngIf="params?.Location !=undefined">
        Location : {{params.Location}} </li>
    <li *ngIf="params?.Eyrepresentative !=undefined">
        EY Respresentative : {{params.Eyrepresentative}} </li>
    <li *ngIf="params?.Notes !=undefined">
        Notes : {{params.Notes}} </li>

        <li *ngIf="params?.Iaaudit1 !=undefined">
            IA Audit 1 : {{params.Iaaudit1}} </li>

          <li *ngIf="params?.Iaaudit1ExecutionStartDate !=undefined">
             Audit 1 Execution Startdate : {{params.Iaaudit1ExecutionStartDate | momentPipe :'LL'}} </li>
          <li *ngIf="params?.Iaaudit1ExecutionEndDate !=undefined">
              Audit 1 Execution Enddate :  {{params.Iaaudit1ExecutionEndDate| momentPipe :'LL'}} </li>
          <li *ngIf="params?.Iaaudit2 !=undefined">
             IA Audit 2 : {{params.Iaaudit2}} </li>
          <li *ngIf="params?.Iaaudit2ExecutionStartDate !=undefined">
              Audit 2 Execution Startdate:  {{params.Iaaudit2ExecutionStartDate| momentPipe :'LL'}} </li>
          <li *ngIf="params?.Iaaudit2ExecutionEndDate !=undefined">
              Audit 2 Execution Enddate   {{params.Iaaudit2ExecutionEndDate| momentPipe :'LL'}} </li>


</ul>