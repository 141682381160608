import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { AuditAssignemntTableActionsComponent } from '../audit-assignment-table-actions/audit-assignment-table-actions.component';
import { AuditAssignmentService } from './audit-assignment.service';
import * as moment from 'moment';
import { AuditassignmenttypechangeslogComponent } from '../auditassignmenttypechangeslog/auditassignmenttypechangeslog.component'
import { AuditassignmentchangeslogComponent } from '../auditassignmentchangeslog/auditassignmentchangeslog.component'
import { saveAs } from 'file-saver';
import { HeaderService } from 'src/app/core/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { dataTool } from 'echarts';

@Component({
  selector: 'app-audit-assignment',
  templateUrl: './audit-assignment.component.html',
  styleUrls: ['./audit-assignment.component.scss']
})
export class AuditAssignmentComponent implements OnInit {

  ////#region Upload variables
  public deletemodalflag: boolean = false;
  public AuditAssignmentId: any;
  public showFileUploadModel: boolean = false;
  Uploader: FileUploader = new FileUploader({ url: '' });
  public uploadfileerror: boolean = false;
  public uploadshowprogress: boolean = false;
  public uploadedfile: any;
  public Validationsmodalflag: boolean = false;
  public invalidarryKeys: any;
  public validationdata: any;
  ////#endregion

  ////#region toaster and Loader variables
  public toastflag: boolean = false;
  public toasttype: string;
  public toastmessage: string;
  ////#endregion
  ////#region tab region
  public currentactivetab: string = 'AuditAssignment';
  public AddUpdateFlag: boolean = false;
  public EditParams: any = [];
  public auditassign: boolean = true;

  ////#endregion

  ////#region actions variables
  public divFlag: boolean = false;
  public Aggridstyle = {
    marginTop: "",
    width: "",
    height: ""
  };
  public styleDIV = {
    marginTop: "",
    width: "",
    height: ""
  };
  public actionAccess: any;
  ////#endregion
  //#region Exce export modal
  public ExcelExportmodalflag: boolean = false;
  ExcelToexportForm: FormGroup;
  ////#endregion
  //#region filtervariables
  public lstYear: any = [];
  public RegionSelected: any;
  public selectedYear: any;
  public currentmonth:any;
  public irofficeSelected: any;
  public BankSelected="allBanks";
  public isMyBanks:boolean=false;
  public NgSearchFlag: boolean = true;
  //#endregion
  public ArchiveFlag: boolean = false;
  constructor(private router: Router, private _headerService: HeaderService, private _auditassignmentService: AuditAssignmentService, private _formBuilder: FormBuilder, private location: Location, private route: ActivatedRoute) { }

  public filterdata: any;
  //#region ag-grid_variables
  public ngSearchFilter: any;
  modules = AllCommunityModules;
  public tableDataset: any;
  public ColumnDefs: any;
  public frameworkComponents: any;


  //#region audit

  public auditTrailActiveTab = "Timeline";

  public AuditTrailDataset: any;
  public AuditTrailfilterDataset: any;
  public adtinsertshow: boolean = true;
  public adtbulkinsertshow: boolean = true;
  public adtupdateshow: boolean = true;
  public adtdeleteshow: boolean = true;
  public AuditAssignmentColumnDefs: any;
  public AuditAssignmentAuditDataSet: any = [];
  auditframeworkComponents: any;

  auditgridApi: any;
  public rowSelection: any = 'multiple';

  public style = {
    height: ""
  };
  setTableHeight() {
    this.Aggridstyle.marginTop = '4px';
    this.Aggridstyle.width = "100%";
    this.Aggridstyle.height = (document.documentElement.clientHeight - 221) + "px";
  }
  public overlayLoadingTemplate: string =
    ' <span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  ////#endregion
  //#region massUpdate properties
  public MassUpdateForm: FormGroup;
  public MassUpdateFlag: boolean = false;
  public lstFormStatus: any;
  //#endregion


  ngOnInit(): void {

    this.setDivHeight();
    this._headerService.getActions("Audit Assignment").then((value) => {

      this.actionAccess = value;
      console.log("auditassignment actions" +this.actionAccess);
      if (this.actionAccess.indexOf("View") != -1) {
        this.divFlag = true;
        this.BuildHeader();
        this.setFrameworkoptions();
        this.GetAuditassignmentDetils();
        //this.EditstartupAuditassignmentDetils();
        this.setTableHeight();
        this.GetRoleBasedRegion();
        this.GetFormStatusData();
        this.getRegionData();
        this.GeneratYear();
        var yearData = this._auditassignmentService.getData();
         if (yearData["AuditYear"] != ""){
           this.selectedYear = yearData["AuditYear"];
          }
         else{
          this.currentmonth=new Date().getMonth();
          this.selectedYear = new Date().getFullYear();
         }
         if (this.currentmonth<=2){
          this.selectedYear=this.selectedYear-1;
          this.externalYearFilterChanged(this.selectedYear);
         }
         else{
          this.externalYearFilterChanged(this.selectedYear);
         }
         
        this.resetFIlters();
        
      }
      else
        this.router.navigate(['/unauthorizedaccess'])
    });

    this.ExcelToexportForm = this.ExceltoExport();
    this.MassUpdateForm = this.MassUpdateFormAuditAssignment();
  }
  gridApi: any;
  pageloadflag: any = true;
  handleGridReady(params) {
    
    this.gridApi = params;
    this.gridApi.api.hideOverlay()  
  };


  lstclientIds:any;
originaldata:any;
   GetAuditassignmentDetils() {
    if(this.BankSelected=="allBanks")
    {
      this.isMyBanks=false;
      this._auditassignmentService.GetAuditassignmentDetils(this.isMyBanks)
      .subscribe(
        res => {
          this.tableDataset = res;
          this.originaldata=this.tableDataset;
          // let data = this._auditassignmentService.getData();
          // if (data["bankcode"] != "")
          //   this.ngSearchFilter = data["bankcode"];
          this.data = this._auditassignmentService.getData();
          let updateid=this._auditassignmentService.getupdateData();
          if(updateid.auditassignmentid!=null && updateid.flag=="update"){
            var mydata = this.tableDataset.filter(function(n,i){
                 return n.auditAssignmentId==updateid.auditassignmentid;
            });
            this.myeditclick(mydata);

          }
          else if (updateid.auditassignmentid!=null && updateid.flag=="view"){
            var mydata = this.tableDataset.filter(function(n,i){
              return n.auditAssignmentId==updateid.auditassignmentid;
         });
         this.myviewclick(mydata);

          }
          updateid=null;
          // let auditAssignmentId="";
          // this._auditassignmentService.setupdateData(auditAssignmentId);
          if (this.data["bankcode"] != ""){
            this.ngSearchFilter = this.data["bankcode"];

          }
          //  this.gridApi.api.hideOverlay();
          this.onFilterTextBoxChanged();
          console.log(this.tableDataset);

        },
        err => {

          console.log(err);
        }
      )
    }
    else{
      this.isMyBanks=true;
      this._auditassignmentService.GetAuditassignmentDetils(this.isMyBanks).subscribe(
        res => {
          this.lstclientIds=res;
          console.log(this.lstclientIds);
          this.tableDataset=this.lstclientIds;
          this.data = this._auditassignmentService.getData();
          let updateid=this._auditassignmentService.getupdateData();
          if(updateid.auditassignmentid!=null && updateid.flag=="update"){
            var mydata = this.tableDataset.filter(function(n,i){
                 return n.auditAssignmentId==updateid.auditassignmentid;
            });
            this.myeditclick(mydata);

          }
          else if (updateid.auditassignmentid!=null && updateid.flag=="view"){
            var mydata = this.tableDataset.filter(function(n,i){
              return n.auditAssignmentId==updateid.auditassignmentid;
         });
         this.myviewclick(mydata);

          }
          updateid=null;
          // let auditAssignmentId="";
          // this._auditassignmentService.setupdateData(auditAssignmentId);
          if (this.data["bankcode"] != ""){
            this.ngSearchFilter = this.data["bankcode"];

          }
          //  this.gridApi.api.hideOverlay();
          this.onFilterTextBoxChanged();
          console.log(this.tableDataset);
        },
        err => {

          console.log(err);
        })


    }





  }



  setFrameworkoptions() {
    this.frameworkComponents = {
      editDeleteActions: AuditAssignemntTableActionsComponent,
    }

    this.auditframeworkComponents = {
      audittypelist: AuditassignmenttypechangeslogComponent,
      auditassignmentlist: AuditassignmentchangeslogComponent
    }

  }
  BuildHeader() {
    this.ColumnDefs = [


      {
        headerName: 'Client Entity',
        headerClass: 'cs-ag-grid-header-two',
        children:
          [

            {
              headerName: 'Bank Code', field: 'bankCodeRgrCid', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 124, pinned: 'left', checkboxSelection: true, headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true
            }
            , { headerName: 'Form Title', field: 'formTitle', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 235, pinned: 'left' }
            , {
              headerName: 'Form Status', field: 'formStatus', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, autoHeight: true, resizable: true, width: 150, pinned: 'left',
              cellStyle: params => {

                if (params.value !== 'Archive') {
                  //mark police cells as red
                  // return { backgroundColor: 'green',color:'white' };
                }
                else
                  return { backgroundColor: 'red', color: 'white' };
              }
            }
          ]
      },
      {
        headerName: 'Client Entity',
        headerClass: 'cs-ag-grid-header-one',
        children:
          [
            , { headerName: 'Classification', field: 'classification', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 140, columnGroupShow: 'closed' }
            , { headerName: 'Classification', field: 'classification', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 140, columnGroupShow: 'open' }
            , { headerName: 'Bank Size', field: 'bankSize', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 120, columnGroupShow: 'open' }
            , { headerName: 'Client Contact', field: 'clientContact', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'EY Region', field: 'eyregionName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 124, columnGroupShow: 'open' }
            , { headerName: 'IR Office', field: 'irofficeName', sortable: true, filter: 'agTextColumnFilter', autoHeight: true, unSortIcon: true, resizable: true, width: 112, columnGroupShow: 'open' }
            , { headerName: 'Reporting Language', field: 'reportingLanguage', sortable: true, autoHeight: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, width: 180, columnGroupShow: 'open' }
          ]
      }
      , {
        headerName: 'Financial Audit - RP',
        headerClass: 'cs-ag-grid-header-RP',
        children:
          [
            { headerName: 'RP Financial Audit Lead', field: 'rpFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }
            , { headerName: 'RP Financial Audit Lead', field: 'rpFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'RP Audit Period Start Date', field: 'rpAuditPeriodStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'RP Audit Period End Date', field: 'rpAuditPeriodEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , { headerName: 'RP Engagement Partner', field: 'rpRegionalLeadPartner', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'RP Engagement Manager', field: 'rpAssignedLeadAuditor', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            // , { headerName: 'RP Assigned Audit Team Members', field: 'rpAssignedAuditTeamMembers', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'RP Internal Audit Engagement Leader', field: 'rpInternalAuditEngagementLeader', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'RP Audit Execution Start Date', field: 'rpAuditExecutionStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'RP Audit Execution End Date', field: 'rpAuditExecutionEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , { headerName: 'Status - RP', field: 'rPstatus.statusName', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open', width: 128 }
            , { headerName: 'IA Work Completed - RP', field: 'rpIaworkCompleted', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' ,
                cellRenderer: (data) => {
                if (data.value != undefined)
                return moment(data.value).format('DD.MM.YYYY')
               }
              }
            ,{ headerName: 'Meeting Format', field: 'meetingFormat', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'AGM Date', field: 'agmDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , { headerName: 'AGM Start Time', field: 'startTime', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'AGM End Time', field: 'endTime', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'AGM Location', field: 'agmLocation', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'EY Representative', field: 'eyRepresentative', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'Notes', field: 'notes', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
          ]
      },
      , {
        headerName: 'Loan Audit - KP',
        headerClass: 'cs-ag-grid-header-KP',
        children:
          [
            { headerName: 'KP Financial Audit Lead', field: 'kpFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }
            , { headerName: 'KP Financial Audit Lead', field: 'kpFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }

            // , {
            //      headerName: 'KP Audit Period Start Date', field: 'kpAuditPeriodStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
            //       cellRenderer: (data) => {
            //       if (data.value != undefined)
            //       return moment(data.value).format('DD.MM.YYYY')
            //      }
            //   }
            // , {
            //      headerName: 'KP Audit Period End Date', field: 'kpAuditPeriodEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
            //       cellRenderer: (data) => {
            //       if (data.value != undefined)
            //       return moment(data.value).format('DD.MM.YYYY')
            //      }
            //   }

            , { headerName: 'KP Engagement Partner', field: 'kpRegionalLeadPartner', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'KP Engagement Manager', field: 'kpAssignedLeadAuditor', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            // , { headerName: 'RP Assigned Audit Team Members', field: 'rpAssignedAuditTeamMembers', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'KP Internal Audit Engagement Leader', field: 'kpInternalAuditEngagementLeader', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }

            //, { headerName: 'KP Assigned Lead Auditor', field: 'kpAssignedLeadAuditor', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            // , { headerName: 'RP Assigned Audit Team Members', field: 'rpAssignedAuditTeamMembers', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'KP Audit Execution Start Date', field: 'kpAuditExecutionStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'KP Audit Execution End Date', field: 'kpAuditExecutionEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , { headerName: 'Status - KP', field: 'kPstatus.statusName', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open', width: 128 }
            , { headerName: 'IA Work Completed - KP', field: 'kpIaworkCompleted', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
                cellRenderer: (data) => {
                if (data.value != undefined)
                return moment(data.value).format('DD.MM.YYYY')
               }
              }

          ]
      },

      , {
        headerName: 'Regulatory - AP',
        headerClass: 'cs-ag-grid-header-AP',
        children:
          [

            { headerName: 'AP Regulatory Audit Lead', field: 'apFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }
            , { headerName: 'AP Regulatory Audit Lead', field: 'apFinancialAuditLead', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'AP Audit Period Start Date', field: 'apAuditPeriodStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'AP Audit Period End Date', field: 'apAuditPeriodEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            //, { headerName: 'AP Regional Lead Partner', field: 'apRegionalLeadPartner', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'AP FINMA Lead Auditor', field: 'apAssignedLeadAuditor', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'AP Engagement Manager', field: 'apRegionalLeadPartner', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            // , { headerName: 'RP Assigned Audit Team Members', field: 'rpAssignedAuditTeamMembers', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , { headerName: 'AP Internal Audit Engagement Leader', field: 'apInternalAuditEngagementLeader', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }

            // , { headerName: 'AP Assigned Audit Team Members', field: 'apAssignedAuditTeamMembers', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            //, { headerName: 'AP Internal Audit Engagement Leader', field: 'apInternalAuditEngagementLeader', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'AP Audit Execution Start Date', field: 'apAuditExecutionStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'AP Audit Execution End Date', field: 'apAuditExecutionEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , { headerName: 'Status - AP', field: 'aPstatus.statusName', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open', width: 128 }
            , { headerName: 'IA Work Completed - AP', field: 'apIaworkCompleted', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
                cellRenderer: (data) => {
                if (data.value != undefined)
                return moment(data.value).format('DD.MM.YYYY')
               }
              }
            , { headerName: 'Reg. Audit Co-Sign', field: 'regAuditCoSign', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            


          ]
      }, {
        headerName: 'Internal Audit- IR',
        headerClass: 'cs-ag-grid-header-IR',
        children:
          [
            { headerName: 'IA Audit1', field: 'iaAudit1', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' },
            { headerName: 'IA Audit1', field: 'iaAudit1', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
                 headerName: 'IA Audit 1 Execution Start Date', field: 'iaAudit1ExecutionStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
                  cellRenderer: (data) => {
                  if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
                 }
              }
            , {
                 headerName: 'IA Audit 1 Execution End Date', field: 'iaAudit1ExecutionEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
                  cellRenderer: (data) => {
                  if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
                 }
              }
            , { headerName: 'IA Audit2', field: 'iaAudit2', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open' }
            , {
              headerName: 'IA Audit 2 Execution Start Date', field: 'iaAudit2ExecutionStartDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
            , {
              headerName: 'IA Audit 2 Execution End Date', field: 'iaAudit2ExecutionEndDate', autoHeight: true, sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'open',
              cellRenderer: (data) => {
                if (data.value != undefined)
                  return moment(data.value).format('DD.MM.YYYY')
              }
            }
          ]
      }


    ]
    //if(this.actionAccess.indexOf("Delete")!=-1 || this.actionAccess.indexOf("Update")!=-1){
    this.ColumnDefs.unshift({
      headerName: '',
      cellRenderer: "editDeleteActions",
      cellRendererParams: {
        onEditClick: this.onEditClick.bind(this),
        onDeleteClick: this.onDeleteClick.bind(this),
        onViewClick: this.onViewClick.bind(this),
        onDownloadAGMICSFile: this.onDownloadAGMICSFile.bind(this),
        Actions: this.actionAccess
      },
      width: 100,
      sortable: false,
      pinned: 'left',
      autoHeight: false,
      filter: false,

    });
    //}
  }
  // onBack(status: string) {
  //   this.AddUpdateFlag = false;
  //   if (status == "success") {
  //     this.toasttype = "success";
  //     this.toastflag = true;
  //     this.toastmessage = "Audit Assignment has been added successfully.";
  //     this.GetAuditassignmentDetils();
  //   }
  //   else if (status == "update") {
  //     this.toasttype = "success";
  //     this.toastflag = true;
  //     this.toastmessage = "Audit Assignment has been updated successfully.";
  //     this.GetAuditassignmentDetils();
  //   }
  //   this.EditParams = null;
  // }
  onBack(status: string) {
    this.AddUpdateFlag = false;
    this.ArchiveFlag = false;
    this.diasabledEditFlag = false;
    if (status == "success") {
      this.toasttype = "success";
      this.toastflag = true;
      this.toastmessage = "Audit Assignment has been added successfully.";
      // this.GetAuditassignmentDetils();
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
    }
    else if (status == "update") {
      this.toasttype = "success";
      this.toastflag = true;
      this.toastmessage = "Audit Assignment has been updated successfully.";
      this.router.navigate(['/auditassignment']);
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);

    }
    else{
      this.router.navigate(['/auditassignment']);
    }

  
    this.GetAuditassignmentDetils();
    this.EditParams = null;
  }
  public diasabledEditFlag: boolean = false;
  public advanceUpdate: boolean = true;
  public data:any;
  myeditclick(row){
    var id = this._headerService.encryptUsingAES256(row[0].auditAssignmentId);
    this.location.go('/auditassignment?id=' + id+'&flag=update');
    this.AddUpdateFlag = true;
    this.EditParams = row[0];
    if (this.actionAccess.indexOf("Update(Advance)") != -1) {
      this.advanceUpdate = false;
    }
    else
      this.advanceUpdate = true;
    this.auditassign = true;

  }
  myviewclick(row){
    var id = this._headerService.encryptUsingAES256(row[0].auditAssignmentId);

    if (row[0].formStatus == "Archive") {
      this.ArchiveFlag = true;
      this.EditParams = row[0];
      var id = this._headerService.encryptUsingAES256(row[0].auditAssignmentId)
      this.location.go('/auditassignment?id=' + id+'&flag=view');
      this.auditassign = false;
    }
    else {


      this.AddUpdateFlag = true;
      this.EditParams = row[0];
      var id = this._headerService.encryptUsingAES256(row[0].auditAssignmentId)
      this.location.go('/auditassignment?id=' + id+'&flag=view');
      this.auditassign = false;
    }
  }
  onEditClick(e) {

    // if(this.RoleBasedRegion[0]["regions"].toString().indexOf(e.rowData.eyregionId)==-1){
    //   this.diasabledEditFlag=true;
    // }
    // else   
    var id = this._headerService.encryptUsingAES256(e.rowData.auditAssignmentId);
    this.location.go('/auditassignment?id=' + id+'&flag=update');
    this.AddUpdateFlag = true;
    // if(this.data.update==true){
    // var myrow=e.tableDataset.filter(x=>x.bankCodeRgrCid == this.data.bankCode);
    // this.EditParams=myrow;
    // }
    // else{
    this.EditParams = e.rowData;
    // }
    var obj = {
      newflag: "AUDupdate",
    }
    this._auditassignmentService.setupdateData(obj);
    if (this.actionAccess.indexOf("Update(Advance)") != -1) {
      this.advanceUpdate = false;
    }
    else
      this.advanceUpdate = true;
    this.auditassign = true;
  }
  onViewClick(e) {
    this.advanceUpdate=true;
    if (e.rowData.formStatus == "Archive") {
      this.ArchiveFlag = true;
      this.EditParams = e.rowData;
      var id = this._headerService.encryptUsingAES256(e.rowData.auditAssignmentId);
      this.location.go('/auditassignment?id=' + id+'&flag=view');
      this.auditassign = false;
      var obj = {
        newflag: "",
      }
      this._auditassignmentService.setupdateData(obj);
    }
    else {


      this.AddUpdateFlag = true;
      this.EditParams = e.rowData;
      var id = this._headerService.encryptUsingAES256(e.rowData.auditAssignmentId)
      this.location.go('/auditassignment?id=' + id+'&flag=view');
      this.auditassign = false;
      var obj = {
        newflag: "",
      }
      this._auditassignmentService.setupdateData(obj);
    }
  }
  ////#region  Upload Code
  fileChange($event) {
    if ($event.length > 0)
      this.uploadedfile = $event[0].file.rawFile;
  }

  clearFiles() {
    this.uploadedfile = null;
    this.uploadfileerror = false;
    this.Uploader = new FileUploader({ url: '' });
  }

  public ShowFileUploadModel() {
    this.showFileUploadModel = true;
  }

  Addauditassigment(){
    this.advanceUpdate=false;
    this.AddUpdateFlag=true;
    this.auditassign=true;
    var obj = {
      newflag: "",
    }
    this._auditassignmentService.setupdateData(obj);

  }


  UploadData() {
    this.toastflag = false;
    this.uploadshowprogress = true;

    let formData: FormData = new FormData();
    formData.append("file", this.uploadedfile);
    this._auditassignmentService.Upload(formData)
      .subscribe(
        res => {


          if (res["status"] == "success") {


            this.hideFileUploadModel();
            this.uploadshowprogress = false;
            this.toasttype = "success";
            this.toastflag = true;

            this.toastmessage = "Audit assignments has been uploaded successfully.";
            this.GetAuditassignmentDetils();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["status"] == "fail" || res["status"] == "invalidfile") {

            this.uploadfileerror = true;
            //   this.toasttype="error";
            this.uploadshowprogress = false;
            //  this.toastflag=true;

            //   this.toastmessage="Error occured please contact support team";
          }
          else if (res["status"] == "invalid") {
            this.hideFileUploadModel();
            //  this.toasttype="error";

            this.Validationsmodalflag = true;
            this.uploadshowprogress = false;
            this.invalidarryKeys = Object.keys(res).slice(0, -1);
            this.validationdata = res;
            // this.toastmessage="Please Upload Valid Template";
          }


        },
        err => {

          if (err == "Internal Server Error") {

            this.uploadfileerror = true;
            //   this.toasttype="error";
            this.uploadshowprogress = false;
            //  this.toastflag=true;

          } else {
            this.hideFileUploadModel();
            this.toasttype = "error";
            this.toastflag = true;

            this.uploadshowprogress = false;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
        }
      )

  }


  public hideFileUploadModel() {
    this.showFileUploadModel = false;
    this.clearFiles();
  }

  ////#endregion
  //#region Excel export modal methods
  private ExceltoExport(): FormGroup {
    return this._formBuilder.group({
      chkFormTitle: [],
      chkAuditYear: [],
      chkClassification: [],
      chkGroupScope: [],
      chkClientContacts: [],
      chkClientEntityName: [],
      chkClientShortEntityName: [],
      chkCanton: [],
      chkBankCode: [],
      chkBankCodeNumeric: [],
      chkLocation: [],
      chkBankSize: [],
      //chkEYOffice: [],
      chkEYRegion: [],
      chkIRRBOffice: [],
      chkReportingLanguage: [],
      radioexceltype: [],
      chkFinancialAuditLeadRP: [],
      chkAuditPeriodStartDateRP: [],
      chkAuditPeriodEndDateRP: [],
      chkRegionalLeadPartnerRP: [],
      chkAssignedLeadAuditorRP: [],
      chkInternalAuditEngagementLeaderRP: [],
      chkAuditExecutionStartDateRP: [],
      chkAuditExecutionEndDateRP: [],
      chkAuditIdcodeRP: [],
      chkStatusRP: [],
      chkFinalreporttoclientdueRP: [],
      chkClientfeedbackdueRP: [],
      chkDraftreporttoclientdueRP: [],
      chkIAworkpaperreviewdueRP: [],
      chkIAworkcompletedRP: [],
      chkDecisiveDate: [],
      chkLatestSignoffDate:[],
      chkActualSignoffDate: [],
      chkDraftreportRP: [],
      chkClientdatadueRP: [],
      chkAnnouncementdeliveryRP: [],
      chkMeetingFormat: [],
      chkAGMDate: [],
      chkAGMStartTime: [],
      chkAGMEndTime: [],
      chkAGMLocation: [],
      chkEYRepresentative: [],
      chkregAuditCoSign:[],
      chkAGMNotes: [],
      chkFinancialAuditLeadKP: [],
      chkRegionalLeadPartnerKP: [],
      chkAssignedLeadAuditorKP: [],
      chkInternalAuditEngagementLeaderKP: [],
      chkAuditExecutionStartDateKP: [],
      chkAuditExecutionEndDateKP: [],
      chkAuditIdcodeKP: [],
      chkStatusKP: [],
      chkFinalreporttoclientdueKP: [],
      chkClientfeedbackdueKP: [],
      chkDraftreporttoclientdueKP: [],
      chkIAworkpaperreviewdueKP: [],
      chkIAworkcompletedKP: [],
      chkDraftreportKP: [],
      chkClientdatadueKP: [],
      chkAnnouncementdeliveryKP: [],
      chkFinancialAuditLeadAP: [],
      chkAuditPeriodStartDateAP: [],
      chkAuditPeriodEndDateAP: [],
      chkRegionalLeadPartnerAP: [],
      chkAssignedLeadAuditorAP: [],
      chkInternalAuditEngagementLeaderAP: [],
      chkAuditExecutionStartDateAP: [],
      chkAuditExecutionEndDateAP: [],
      chkAuditIdcodeAP: [],
      chkStatusAP: [],
      chkFinalreporttoclientdueAP: [],
      chkClientfeedbackdueAP: [],
      chkDraftreporttoclientdueAP: [],
      chkIAworkpaperreviewdueAP: [],
      chkIAworkcompletedAP: [],
      chkDraftreportAP: [],
      chkClientdatadueAP: [],
      chkAnnouncementdeliveryAP: [],
      chkFinancialAuditLeadOT: [],
      chkAuditPeriodStartDateOT: [],
      chkAuditPeriodEndDateOT: [],
      chkRegionalLeadPartnerOT: [],
      chkAssignedLeadAuditorOT: [],
      // chkAssignedAuditTeamMembersOT: [],
      chkAuditExecutionStartDateOT: [],
      chkAuditExecutionEndDateOT: [],
      chkAuditIdcodeOT: [],
      chkStatusOT: [],
      chkFinalreporttoclientdueOT: [],
      chkClientfeedbackdueOT: [],
      chkDraftreporttoclientdueOT: [],
      chkIAworkpaperreviewdueOT: [],
      chkIAworkcompletedOT: [],
      chkDraftreportOT: [],
      chkClientdatadueOT: [],
      chkAnnouncementdeliveryOT: [],
      chkComment: [],
      chkFormStatus: [],
      chkIAAudit1: [],
      chkIAAudit1StartDate: [],
      chkIAAudit1EndDate: [],
      chkIAAudit2: [],
      chkIAAudit2StartDate: [],
      chkIAAudit2EndDate: []
    });
  }
  ExcelExportpopup() {
    this.toastflag = false;
    this.ExcelExportmodalflag = true;
    this.ExcelToexportForm.setValue({
      chkFormTitle: true,
      chkAuditYear: true,
      chkClassification: true,
      chkGroupScope: true,
      chkClientContacts: true,
      chkClientEntityName: true,
      chkClientShortEntityName: true,
      chkCanton: true,
      chkBankCode: true,
      chkBankCodeNumeric: true,
      chkLocation: true,
      chkBankSize: true,
      //chkEYOffice: true,
      chkEYRegion: true,
      chkIRRBOffice: true,
      chkReportingLanguage: true,
      chkFinancialAuditLeadRP: true,
      chkAuditPeriodStartDateRP: true,
      chkAuditPeriodEndDateRP: true,
      chkRegionalLeadPartnerRP: true,
      chkAssignedLeadAuditorRP: true,
      chkInternalAuditEngagementLeaderRP: true,
      chkAuditExecutionStartDateRP: true,
      chkAuditExecutionEndDateRP: true,
      chkAuditIdcodeRP: true,
      chkStatusRP: true,
      chkFinalreporttoclientdueRP: true,
      chkClientfeedbackdueRP: true,
      chkDraftreporttoclientdueRP: true,
      chkIAworkpaperreviewdueRP: true,
      chkIAworkcompletedRP: true,
      chkDecisiveDate:true,
      chkLatestSignoffDate:true,
      chkActualSignoffDate:true,
      chkDraftreportRP: true,
      chkClientdatadueRP: true,
      chkAnnouncementdeliveryRP: true,
      chkMeetingFormat: true,
      chkAGMDate: true,
      chkAGMStartTime: true,
      chkAGMEndTime: true,
      chkAGMLocation: true,
      chkEYRepresentative: true,
      chkregAuditCoSign:true,
      chkAGMNotes: true,
      chkFinancialAuditLeadKP: true,
      chkRegionalLeadPartnerKP: true,
      chkAssignedLeadAuditorKP: true,
      chkInternalAuditEngagementLeaderKP: true,

      chkAuditExecutionStartDateKP: true,
      chkAuditExecutionEndDateKP: true,
      chkAuditIdcodeKP: true,
      chkStatusKP: true,
      chkFinalreporttoclientdueKP: true,
      chkClientfeedbackdueKP: true,
      chkDraftreporttoclientdueKP: true,
      chkIAworkpaperreviewdueKP: true,
      chkIAworkcompletedKP: true,
      chkDraftreportKP: true,
      chkClientdatadueKP: true,
      chkAnnouncementdeliveryKP: true,

      chkFinancialAuditLeadAP: true,
      chkAuditPeriodStartDateAP: true,
      chkAuditPeriodEndDateAP: true,
      chkRegionalLeadPartnerAP: true,
      chkAssignedLeadAuditorAP: true,
      chkInternalAuditEngagementLeaderAP: true,
      chkAuditExecutionStartDateAP: true,
      chkAuditExecutionEndDateAP: true,
      chkAuditIdcodeAP: true,
      chkStatusAP: true,
      chkFinalreporttoclientdueAP: true,
      chkClientfeedbackdueAP: true,
      chkDraftreporttoclientdueAP: true,
      chkIAworkpaperreviewdueAP: true,
      chkIAworkcompletedAP: true,
      chkDraftreportAP: true,
      chkClientdatadueAP: true,
      chkAnnouncementdeliveryAP: true,

      chkFinancialAuditLeadOT: true,
      chkAuditPeriodStartDateOT: true,
      chkAuditPeriodEndDateOT: true,
      chkRegionalLeadPartnerOT: true,
      chkAssignedLeadAuditorOT: true,
      //chkAssignedAuditTeamMembersOT: true,
      chkAuditExecutionStartDateOT: true,
      chkAuditExecutionEndDateOT: true,
      chkAuditIdcodeOT: true,
      chkStatusOT: true,
      chkFinalreporttoclientdueOT: true,
      chkClientfeedbackdueOT: true,
      chkDraftreporttoclientdueOT: true,
      chkIAworkpaperreviewdueOT: true,
      chkIAworkcompletedOT: true,
      chkDraftreportOT: true,
      chkClientdatadueOT: true,
      chkAnnouncementdeliveryOT: true,

      chkComment: true,
      chkFormStatus: true,
      chkIAAudit1: true,
      chkIAAudit1StartDate: true,
      chkIAAudit1EndDate: true,
      chkIAAudit2: true,
      chkIAAudit2StartDate: true,
      chkIAAudit2EndDate: true,
      radioexceltype: "all"
    })

  }

  Excelexport(form: FormGroup) {

    this.toastflag = false;
    let selectedNodes = this.gridApi.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data.auditAssignmentId);
    let columnNames = [];
    columnNames.push(form.value.chkBankCode == true ? "Bank Code" : "");
    columnNames.push(form.value.chkClientShortEntityName == true ? "Client Entity Short Name" : "");
    columnNames.push(form.value.chkBankCodeNumeric == true ? "Bank Code(Numeric)" : "");
    columnNames.push(form.value.chkFormTitle == true ? "Form Title" : "");
    columnNames.push(form.value.chkAuditYear == true ? "Audit Year" : "");
    columnNames.push(form.value.chkClassification == true ? "Classification" : "");
    columnNames.push(form.value.chkGroupScope == true ? "Group Scope" : "");
    columnNames.push(form.value.chkClientContacts == true ? "Client Contacts" : "");
    columnNames.push(form.value.chkClientEntityName == true ? "Client Entity Name" : "");
    columnNames.push(form.value.chkCanton == true ? "Canton" : "");
    columnNames.push(form.value.chkLocation == true ? "Location" : "");
    columnNames.push(form.value.chkBankSize == true ? "Bank Size" : "");
    // columnNames.push(form.value.chkEYOffice == true ? "EY Office" : "");
    columnNames.push(form.value.chkEYRegion == true ? "EY Region" : "");
    columnNames.push(form.value.chkIRRBOffice == true ? "IR Office" : "");
    columnNames.push(form.value.chkReportingLanguage == true ? "Reporting Language" : "");
    columnNames.push(form.value.chkFinancialAuditLeadRP == true ? "Financial Audit Lead - RP" : "");
    columnNames.push(form.value.chkAuditPeriodStartDateRP == true ? "Audit Period Start Date - RP" : "");
    columnNames.push(form.value.chkAuditPeriodEndDateRP == true ? "Audit Period End Date - RP" : "");
    columnNames.push(form.value.chkRegionalLeadPartnerRP == true ? "Engagement Partner - RP" : "");
    columnNames.push(form.value.chkAssignedLeadAuditorRP == true ? "Engagement Manager - RP" : "");
    columnNames.push(form.value.chkInternalAuditEngagementLeaderRP == true ? "Internal Audit Engagement Leader - RP" : "");
    //columnNames.push(form.value.chkAssignedAuditTeamMembersRP == true ? "Assigned Audit Team Members - RP" : "");
    columnNames.push(form.value.chkAuditExecutionStartDateRP == true ? "Audit Execution Start Date - RP" : "");
    columnNames.push(form.value.chkAuditExecutionEndDateRP == true ? "Audit Execution End Date - RP" : "");
    columnNames.push(form.value.chkDecisiveDate == true ? "Decisive Date - RP" : "");
    columnNames.push(form.value.chkLatestSignoffDate == true ? "Latest Sign-off Date - RP" : "");
    columnNames.push(form.value.chkActualSignoffDate == true ? "Actual Sign-off Date - RP" : "");
    columnNames.push(form.value.chkAnnouncementdeliveryRP == true ? "Announcement Delivery - RP" : "");
    columnNames.push(form.value.chkClientdatadueRP == true ? "Client Data Due - RP" : "");
    columnNames.push(form.value.chkDraftreportRP == true ? "Draft Report - RP" : "");
    columnNames.push(form.value.chkIAworkcompletedRP == true ? "IA Work Completed - RP" : "");
    columnNames.push(form.value.chkIAworkpaperreviewdueRP == true ? "IA Work Paper Review Due - RP" : "");
    columnNames.push(form.value.chkDraftreporttoclientdueRP == true ? "Draft Report To Client Due - RP" : "");
    columnNames.push(form.value.chkClientfeedbackdueRP == true ? "Client Feedback Due - RP" : "");
    columnNames.push(form.value.chkFinalreporttoclientdueRP == true ? "Final Report To Client Due - RP" : "");
    columnNames.push(form.value.chkStatusRP == true ? "Status - RP" : "");
    columnNames.push(form.value.chkAuditIdcodeRP == true ? "Audit Idcode RP" : "");
    columnNames.push(form.value.chkMeetingFormat == true ? "Meeting Format" : "");
    columnNames.push(form.value.chkAGMDate == true ? "Date" : "");
    columnNames.push(form.value.chkAGMStartTime == true ? "Start Time" : "");
    columnNames.push(form.value.chkAGMEndTime == true ? "End Time" : "");
    columnNames.push(form.value.chkAGMLocation == true ? "AGM Location" : "");
    columnNames.push(form.value.chkEYRepresentative == true ? "EY Representative" : "");
    columnNames.push(form.value.chkAGMNotes == true ? "Notes" : "");
    columnNames.push(form.value.chkFinancialAuditLeadKP == true ? "Financial Audit Lead - KP" : "");
    columnNames.push(form.value.chkRegionalLeadPartnerKP == true ? "Engagement Partner - KP" : "");
    columnNames.push(form.value.chkAssignedLeadAuditorKP == true ? "Engagement Manager - KP" : "");
    columnNames.push(form.value.chkInternalAuditEngagementLeaderKP == true ? "Internal Audit Engagement Leader - KP" : "");
    columnNames.push(form.value.chkAuditExecutionStartDateKP == true ? "Audit Execution Start Date - KP" : "");
    columnNames.push(form.value.chkAuditExecutionEndDateKP == true ? "Audit Execution End Date - KP" : "");
    columnNames.push(form.value.chkAnnouncementdeliveryKP == true ? "Announcement Delivery - KP" : "");
    columnNames.push(form.value.chkClientdatadueKP == true ? "Client Data Due - KP" : "");
    columnNames.push(form.value.chkDraftreportKP == true ? "Draft Report - KP" : "");
    columnNames.push(form.value.chkIAworkcompletedKP == true ? "IA Work Completed - KP" : "");
    columnNames.push(form.value.chkIAworkpaperreviewdueKP == true ? "IA Work Paper Review Due - KP" : "");
    columnNames.push(form.value.chkDraftreporttoclientdueKP == true ? "Draft Report To Client Due - KP" : "");
    columnNames.push(form.value.chkClientfeedbackdueKP == true ? "Client Feedback Due - KP" : "");
    columnNames.push(form.value.chkFinalreporttoclientdueKP == true ? "Final Report To Client Due - KP" : "");
    columnNames.push(form.value.chkStatusKP == true ? "Status - KP" : "");
    columnNames.push(form.value.chkAuditIdcodeKP == true ? "Audit Idcode KP" : "");
    columnNames.push(form.value.chkFinancialAuditLeadAP == true ? "Regulatory Audit Lead - AP" : "");
    columnNames.push(form.value.chkAuditPeriodStartDateAP == true ? "Audit Period Start Date - AP" : "");
    columnNames.push(form.value.chkAuditPeriodEndDateAP == true ? "Audit Period End Date - AP" : "");
    columnNames.push(form.value.chkAssignedLeadAuditorAP == true ? "FINMA Lead Auditor - AP" : "");
    columnNames.push(form.value.chkRegionalLeadPartnerAP == true ? "Engagement Manager - AP" : "");
    columnNames.push(form.value.chkInternalAuditEngagementLeaderAP == true ? "Internal Audit Engagement Leader - AP" : "");
    columnNames.push(form.value.chkAuditExecutionStartDateAP == true ? "Audit Execution Start Date - AP" : "");
    columnNames.push(form.value.chkAuditExecutionEndDateAP == true ? "Audit Execution End Date - AP" : "");
    columnNames.push(form.value.chkAnnouncementdeliveryAP == true ? "Announcement Delivery - AP" : "");
    columnNames.push(form.value.chkClientdatadueAP == true ? "Client Data Due - AP" : "");
    columnNames.push(form.value.chkDraftreportAP == true ? "Draft Report - AP" : "");
    columnNames.push(form.value.chkIAworkcompletedAP == true ? "IA Work Completed - AP" : "");
    columnNames.push(form.value.chkIAworkpaperreviewdueAP == true ? "IA Work Paper Review Due - AP" : "");
    columnNames.push(form.value.chkDraftreporttoclientdueAP == true ? "Draft Report To Client Due - AP" : "");
    columnNames.push(form.value.chkClientfeedbackdueAP == true ? "Client Feedback Due - AP" : "");
    columnNames.push(form.value.chkFinalreporttoclientdueAP == true ? "Final Report To Client Due - AP" : "");
    columnNames.push(form.value.chkStatusAP == true ? "Status - AP" : "");
    columnNames.push(form.value.chkregAuditCoSign == true ? "Reg. Audit Co-Sign" : "");
    columnNames.push(form.value.chkAuditIdcodeAP == true ? "Audit Idcode AP" : "");
    columnNames.push(form.value.chkFinancialAuditLeadOT == true ? "Financial Audit Lead - OT" : "");
    columnNames.push(form.value.chkAuditPeriodStartDateOT == true ? "Audit Period Start Date - OT" : "");
    columnNames.push(form.value.chkAuditPeriodEndDateOT == true ? "Audit Period End Date - OT" : "");
    columnNames.push(form.value.chkRegionalLeadPartnerOT == true ? "Partner - OT" : "");
    columnNames.push(form.value.chkAssignedLeadAuditorOT == true ? "Engagement Manager - OT" : "");
    //columnNames.push(form.value.chkAssignedAuditTeamMembersOT == true ? "Assigned Audit Team Members - OT" : "");
    columnNames.push(form.value.chkAuditExecutionStartDateOT == true ? "Audit Execution Start Date - OT" : "");
    columnNames.push(form.value.chkAuditExecutionEndDateOT == true ? "Audit Execution End Date - OT" : "");
    columnNames.push(form.value.chkAnnouncementdeliveryOT == true ? "Announcement Delivery - OT" : "");
    columnNames.push(form.value.chkClientdatadueOT == true ? "Client Data Due - OT" : "");
    columnNames.push(form.value.chkDraftreportOT == true ? "Draft Report - OT" : "");
    columnNames.push(form.value.chkIAworkcompletedOT == true ? "IA Work Completed - OT" : "");
    columnNames.push(form.value.chkIAworkpaperreviewdueOT == true ? "IA Work Paper Review Due - OT" : "");
    columnNames.push(form.value.chkDraftreporttoclientdueOT == true ? "Draft Report To Client Due - OT" : "");
    columnNames.push(form.value.chkClientfeedbackdueOT == true ? "Client Feedback Due - OT" : "");
    columnNames.push(form.value.chkFinalreporttoclientdueOT == true ? "Final Report To Client Due - OT" : "");
    columnNames.push(form.value.chkStatusOT == true ? "Status - OT" : "");
    columnNames.push(form.value.chkAuditIdcodeOT == true ? "Audit Idcode OT" : "");
    columnNames.push(form.value.chkComment == true ? "Comment" : "");
    columnNames.push(form.value.chkFormStatus == true ? "Form Status" : "");
    columnNames.push(form.value.chkIAAudit1 == true ? "IA Audit 1" : "");
    columnNames.push(form.value.chkIAAudit1StartDate == true ? "IA Audit 1 Start Date" : "");
    columnNames.push(form.value.chkIAAudit1EndDate == true ? "IA Audit 1 End Date" : "");
    columnNames.push(form.value.chkIAAudit2 == true ? "IA Audit 2" : "");
    columnNames.push(form.value.chkIAAudit2StartDate == true ? "IA Audit 2 Start Date" : "");
    columnNames.push(form.value.chkIAAudit2EndDate == true ? "IA Audit 2 End Date" : "");

    columnNames = columnNames.filter(function (el) {
      return el != "";
    });
    if (columnNames.length == 0) {
      this.toasttype = "warning";
      this.toastflag = true;

      this.toastmessage = "Please select atleast one column";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    var bodydata = {
      columnNames: columnNames,
      selectedData: selectedData
    }
    this._auditassignmentService.Excelexport(bodydata)
      .subscribe(
        res => {

          this.ExcelExportmodalflag = false;

          let date = new Date().toString();
          saveAs(res, "AuditAssignmentData" + date + ".xlsx");

        },
        err => {

          console.log(err);
        }
      )
  }
  ReportOptionChange() {
    this.toastflag = false;
    if (this.ExcelToexportForm.value.radioexceltype == "all") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: true,
        chkAuditYear: true,
        chkClassification: true,
        chkGroupScope: true,
        chkClientContacts: true,
        chkClientEntityName: true,
        chkClientShortEntityName: true,
        chkCanton: true,
        chkBankCode: true,
        chkBankCodeNumeric: true,
        chkLocation: true,
        chkBankSize: true,
        // chkEYOffice: true,
        chkEYRegion: true,
        chkIRRBOffice: true,
        chkReportingLanguage: true,
        chkFinancialAuditLeadRP: true,
        chkAuditPeriodStartDateRP: true,
        chkAuditPeriodEndDateRP: true,
        chkRegionalLeadPartnerRP: true,
        chkAssignedLeadAuditorRP: true,
        chkInternalAuditEngagementLeaderRP: true,
        chkAuditExecutionStartDateRP: true,
        chkAuditExecutionEndDateRP: true,
        chkAuditIdcodeRP: true,
        chkStatusRP: true,
        chkFinalreporttoclientdueRP: true,
        chkClientfeedbackdueRP: true,
        chkDraftreporttoclientdueRP: true,
        chkIAworkpaperreviewdueRP: true,
        chkIAworkcompletedRP: true,
        chkDecisiveDate:true,
        chkLatestSignoffDate:true,
        chkActualSignoffDate:true,
        chkDraftreportRP: true,
        chkClientdatadueRP: true,
        chkAnnouncementdeliveryRP: true,
        chkMeetingFormat: true,
        chkAGMDate: true,
        chkAGMStartTime: true,
        chkAGMEndTime: true,
        chkAGMLocation: true,
        chkEYRepresentative: true,
        chkregAuditCoSign:true,
        chkAGMNotes: true,

        chkFinancialAuditLeadKP: true,
        chkRegionalLeadPartnerKP: true,
        chkAssignedLeadAuditorKP: true,
        chkInternalAuditEngagementLeaderKP: true,
        chkAuditExecutionStartDateKP: true,
        chkAuditExecutionEndDateKP: true,
        chkAuditIdcodeKP: true,
        chkStatusKP: true,
        chkFinalreporttoclientdueKP: true,
        chkClientfeedbackdueKP: true,
        chkDraftreporttoclientdueKP: true,
        chkIAworkpaperreviewdueKP: true,
        chkIAworkcompletedKP: true,
        chkDraftreportKP: true,
        chkClientdatadueKP: true,
        chkAnnouncementdeliveryKP: true,

        chkFinancialAuditLeadAP: true,
        chkAuditPeriodStartDateAP: true,
        chkAuditPeriodEndDateAP: true,
        chkRegionalLeadPartnerAP: true,
        chkAssignedLeadAuditorAP: true,
        chkInternalAuditEngagementLeaderAP: true,
        chkAuditExecutionStartDateAP: true,
        chkAuditExecutionEndDateAP: true,
        chkAuditIdcodeAP: true,
        chkStatusAP: true,
        chkFinalreporttoclientdueAP: true,
        chkClientfeedbackdueAP: true,
        chkDraftreporttoclientdueAP: true,
        chkIAworkpaperreviewdueAP: true,
        chkIAworkcompletedAP: true,
        chkDraftreportAP: true,
        chkClientdatadueAP: true,
        chkAnnouncementdeliveryAP: true,

        chkFinancialAuditLeadOT: true,
        chkAuditPeriodStartDateOT: true,
        chkAuditPeriodEndDateOT: true,
        chkRegionalLeadPartnerOT: true,
        chkAssignedLeadAuditorOT: true,
        //chkAssignedAuditTeamMembersOT: true,
        chkAuditExecutionStartDateOT: true,
        chkAuditExecutionEndDateOT: true,
        chkAuditIdcodeOT: true,
        chkStatusOT: true,
        chkFinalreporttoclientdueOT: true,
        chkClientfeedbackdueOT: true,
        chkDraftreporttoclientdueOT: true,
        chkIAworkpaperreviewdueOT: true,
        chkIAworkcompletedOT: true,
        chkDraftreportOT: true,
        chkClientdatadueOT: true,
        chkAnnouncementdeliveryOT: true,

        chkComment: true,
        chkFormStatus: true,
        chkIAAudit1: true,
        chkIAAudit1StartDate: true,
        chkIAAudit1EndDate: true,
        chkIAAudit2: true,
        chkIAAudit2StartDate: true,
        chkIAAudit2EndDate: true,
        radioexceltype: "all"
      })
    }
    else if (this.ExcelToexportForm.value.radioexceltype == "AGM") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: false,
        chkAuditYear: true,
        chkClassification: false,
        chkGroupScope: false,
        chkClientContacts: false,
        chkClientEntityName: false,
        chkClientShortEntityName: true,
        chkCanton: false,
        chkBankCode: true,
        chkBankCodeNumeric: true,
        chkLocation: false,
        chkBankSize: false,
        //  chkEYOffice: false,
        chkEYRegion: true,
        chkIRRBOffice: false,
        chkReportingLanguage: true,
        chkFinancialAuditLeadRP: true,
        chkAuditPeriodStartDateRP: false,
        chkAuditPeriodEndDateRP: false,
        chkRegionalLeadPartnerRP: true,
        chkAssignedLeadAuditorRP: true,
        chkInternalAuditEngagementLeaderRP: false,
        chkAuditExecutionStartDateRP: false,
        chkAuditExecutionEndDateRP: false,
        chkAuditIdcodeRP: false,
        chkStatusRP: false,
        chkFinalreporttoclientdueRP: false,
        chkClientfeedbackdueRP: false,
        chkDraftreporttoclientdueRP: false,
        chkIAworkpaperreviewdueRP: false,
        chkIAworkcompletedRP: false,
        chkDecisiveDate:true,
        chkLatestSignoffDate:true,
        chkActualSignoffDate:false,
        chkDraftreportRP: false,
        chkClientdatadueRP: false,
        chkAnnouncementdeliveryRP: false,
        chkMeetingFormat: true,
        chkAGMDate: true,
        chkAGMStartTime: true,
        chkAGMEndTime: true,
        chkAGMLocation: true,
        chkEYRepresentative: true,
        chkAGMNotes: true,
        chkFinancialAuditLeadKP: false,
        chkRegionalLeadPartnerKP: false,
        chkAssignedLeadAuditorKP: false,
        chkInternalAuditEngagementLeaderKP: false,
        chkAuditExecutionStartDateKP: false,
        chkAuditExecutionEndDateKP: false,
        chkAuditIdcodeKP: false,
        chkStatusKP: false,
        chkFinalreporttoclientdueKP: false,
        chkClientfeedbackdueKP: false,
        chkDraftreporttoclientdueKP: false,
        chkIAworkpaperreviewdueKP: false,
        chkIAworkcompletedKP: false,
        chkDraftreportKP: false,
        chkClientdatadueKP: false,
        chkAnnouncementdeliveryKP: false,

        chkFinancialAuditLeadAP: false,
        chkAuditPeriodStartDateAP: false,
        chkAuditPeriodEndDateAP: false,
        chkRegionalLeadPartnerAP: false,
        chkAssignedLeadAuditorAP: false,
        chkInternalAuditEngagementLeaderAP: false,
        chkAuditExecutionStartDateAP: false,
        chkAuditExecutionEndDateAP: false,
        chkAuditIdcodeAP: false,
        chkStatusAP: false,
        chkregAuditCoSign:false,
        chkFinalreporttoclientdueAP: false,
        chkClientfeedbackdueAP: false,
        chkDraftreporttoclientdueAP: false,
        chkIAworkpaperreviewdueAP: false,
        chkIAworkcompletedAP: false,
        chkDraftreportAP: false,
        chkClientdatadueAP: false,
        chkAnnouncementdeliveryAP: false,

        chkFinancialAuditLeadOT: false,
        chkAuditPeriodStartDateOT: false,
        chkAuditPeriodEndDateOT: false,
        chkRegionalLeadPartnerOT: false,
        chkAssignedLeadAuditorOT: false,
        //chkAssignedAuditTeamMembersOT: false,
        chkAuditExecutionStartDateOT: false,
        chkAuditExecutionEndDateOT: false,
        chkAuditIdcodeOT: false,
        chkStatusOT: false,
        chkFinalreporttoclientdueOT: false,
        chkClientfeedbackdueOT: false,
        chkDraftreporttoclientdueOT: false,
        chkIAworkpaperreviewdueOT: false,
        chkIAworkcompletedOT: false,
        chkDraftreportOT: false,
        chkClientdatadueOT: false,
        chkAnnouncementdeliveryOT: false,

        chkComment: false,
        chkFormStatus: false,
        chkIAAudit1: false,
        chkIAAudit1StartDate: false,
        chkIAAudit1EndDate: false,
        chkIAAudit2: false,
        chkIAAudit2StartDate: false,
        chkIAAudit2EndDate: false,
        radioexceltype: "AGM"
      })

    }

    else if (this.ExcelToexportForm.value.radioexceltype == "RegulatoryAudit") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: false,
        chkAuditYear: true,
        chkClassification: true,
        chkGroupScope: false,
        chkClientContacts: false,
        chkClientEntityName: false,
        chkClientShortEntityName: true,
        chkCanton: false,
        chkBankCode: true,
        chkBankCodeNumeric: true,
        chkLocation: false,
        chkBankSize: false,
        //  chkEYOffice: false,
        chkEYRegion: true,
        chkIRRBOffice: true,
        chkReportingLanguage: true,
        chkFinancialAuditLeadRP: false,
        chkAuditPeriodStartDateRP: false,
        chkAuditPeriodEndDateRP: false,
        chkRegionalLeadPartnerRP: false,
        chkAssignedLeadAuditorRP: false,
        chkInternalAuditEngagementLeaderRP: false,
        chkAuditExecutionStartDateRP: false,
        chkAuditExecutionEndDateRP: false,
        chkAuditIdcodeRP: false,
        chkStatusRP: false,
        chkFinalreporttoclientdueRP: false,
        chkClientfeedbackdueRP: false,
        chkDraftreporttoclientdueRP: false,
        chkIAworkpaperreviewdueRP: false,
        chkIAworkcompletedRP: false,
        chkDecisiveDate:false,
        chkLatestSignoffDate:false,
        chkActualSignoffDate:false,
        chkDraftreportRP: false,
        chkClientdatadueRP: false,
        chkAnnouncementdeliveryRP: false,
        chkMeetingFormat: false,
        chkAGMDate: false,
        chkAGMStartTime: false,
        chkAGMEndTime: false,
        chkAGMLocation: false,
        chkEYRepresentative: false,
        chkAGMNotes: false,
        chkFinancialAuditLeadKP: false,
        chkRegionalLeadPartnerKP: false,
        chkAssignedLeadAuditorKP: false,
        chkInternalAuditEngagementLeaderKP: false,
        chkAuditExecutionStartDateKP: false,
        chkAuditExecutionEndDateKP: false,
        chkAuditIdcodeKP: false,
        chkStatusKP: false,
        chkFinalreporttoclientdueKP: false,
        chkClientfeedbackdueKP: false,
        chkDraftreporttoclientdueKP: false,
        chkIAworkpaperreviewdueKP: false,
        chkIAworkcompletedKP: false,
        chkDraftreportKP: false,
        chkClientdatadueKP: false,
        chkAnnouncementdeliveryKP: false,

        chkFinancialAuditLeadAP: true,
        chkAuditPeriodStartDateAP: true,
        chkAuditPeriodEndDateAP: true,
        chkRegionalLeadPartnerAP: true,
        chkAssignedLeadAuditorAP: true,
        chkInternalAuditEngagementLeaderAP: true,
        chkAuditExecutionStartDateAP: true,
        chkAuditExecutionEndDateAP: true,
        chkAuditIdcodeAP: false,
        chkStatusAP: true,
        chkregAuditCoSign:true,
        chkFinalreporttoclientdueAP: true,
        chkClientfeedbackdueAP: true,
        chkDraftreporttoclientdueAP: true,
        chkIAworkpaperreviewdueAP: true,
        chkIAworkcompletedAP: true,
        chkDraftreportAP: true,
        chkClientdatadueAP: true,
        chkAnnouncementdeliveryAP: true,

        chkFinancialAuditLeadOT: false,
        chkAuditPeriodStartDateOT: false,
        chkAuditPeriodEndDateOT: false,
        chkRegionalLeadPartnerOT: false,
        chkAssignedLeadAuditorOT: false,
        //chkAssignedAuditTeamMembersOT: false,
        chkAuditExecutionStartDateOT: false,
        chkAuditExecutionEndDateOT: false,
        chkAuditIdcodeOT: false,
        chkStatusOT: false,
        chkFinalreporttoclientdueOT: false,
        chkClientfeedbackdueOT: false,
        chkDraftreporttoclientdueOT: false,
        chkIAworkpaperreviewdueOT: false,
        chkIAworkcompletedOT: false,
        chkDraftreportOT: false,
        chkClientdatadueOT: false,
        chkAnnouncementdeliveryOT: false,

        chkComment: false,
        chkFormStatus: false,
        chkIAAudit1: false,
        chkIAAudit1StartDate: false,
        chkIAAudit1EndDate: false,
        chkIAAudit2: false,
        chkIAAudit2StartDate: false,
        chkIAAudit2EndDate: false,
        radioexceltype: "RegulatoryAudit"
      })

    }
    else if (this.ExcelToexportForm.value.radioexceltype == "LoanAudit") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: false,
        chkAuditYear: true,
        chkClassification: true,
        chkGroupScope: false,
        chkClientContacts: false,
        chkClientEntityName: false,
        chkClientShortEntityName: true,
        chkCanton: false,
        chkBankCode: true,
        chkBankCodeNumeric: true,
        chkLocation: false,
        chkBankSize: false,
        //  chkEYOffice: false,
        chkEYRegion: true,
        chkIRRBOffice: true,
        chkReportingLanguage: true,
        chkFinancialAuditLeadRP: false,
        chkAuditPeriodStartDateRP: false,
        chkAuditPeriodEndDateRP: false,
        chkRegionalLeadPartnerRP: false,
        chkAssignedLeadAuditorRP: false,
        chkInternalAuditEngagementLeaderRP: false,
        chkAuditExecutionStartDateRP: false,
        chkAuditExecutionEndDateRP: false,
        chkAuditIdcodeRP: false,
        chkStatusRP: false,
        chkFinalreporttoclientdueRP: false,
        chkClientfeedbackdueRP: false,
        chkDraftreporttoclientdueRP: false,
        chkIAworkpaperreviewdueRP: false,
        chkIAworkcompletedRP: false,
        chkDecisiveDate:false,
        chkLatestSignoffDate:false,
        chkActualSignoffDate:false,
        chkDraftreportRP: false,
        chkClientdatadueRP: false,
        chkAnnouncementdeliveryRP: false,
        chkMeetingFormat: false,
        chkAGMDate: false,
        chkAGMStartTime: false,
        chkAGMEndTime: false,
        chkAGMLocation: false,
        chkEYRepresentative: false,
        chkAGMNotes: false,
        chkFinancialAuditLeadKP: true,
        chkRegionalLeadPartnerKP: true,
        chkAssignedLeadAuditorKP: true,
        chkInternalAuditEngagementLeaderKP: true,
        chkAuditExecutionStartDateKP: true,
        chkAuditExecutionEndDateKP: true,
        chkAuditIdcodeKP: false,
        chkStatusKP: true,
        chkFinalreporttoclientdueKP: true,
        chkClientfeedbackdueKP: false,
        chkDraftreporttoclientdueKP: false,
        chkIAworkpaperreviewdueKP: true,
        chkIAworkcompletedKP: true,
        chkDraftreportKP: false,
        chkClientdatadueKP: true,
        chkAnnouncementdeliveryKP: true,

        chkFinancialAuditLeadAP: false,
        chkAuditPeriodStartDateAP: false,
        chkAuditPeriodEndDateAP: false,
        chkRegionalLeadPartnerAP: false,
        chkAssignedLeadAuditorAP: false,
        chkInternalAuditEngagementLeaderAP: false,
        chkAuditExecutionStartDateAP: false,
        chkAuditExecutionEndDateAP: false,
        chkAuditIdcodeAP: false,
        chkStatusAP: false,
        chkregAuditCoSign:false,
        chkFinalreporttoclientdueAP: false,
        chkClientfeedbackdueAP: false,
        chkDraftreporttoclientdueAP: false,
        chkIAworkpaperreviewdueAP: false,
        chkIAworkcompletedAP: false,
        chkDraftreportAP: false,
        chkClientdatadueAP: false,
        chkAnnouncementdeliveryAP: false,

        chkFinancialAuditLeadOT: false,
        chkAuditPeriodStartDateOT: false,
        chkAuditPeriodEndDateOT: false,
        chkRegionalLeadPartnerOT: false,
        chkAssignedLeadAuditorOT: false,
        //chkAssignedAuditTeamMembersOT: false,
        chkAuditExecutionStartDateOT: false,
        chkAuditExecutionEndDateOT: false,
        chkAuditIdcodeOT: false,
        chkStatusOT: false,
        chkFinalreporttoclientdueOT: false,
        chkClientfeedbackdueOT: false,
        chkDraftreporttoclientdueOT: false,
        chkIAworkpaperreviewdueOT: false,
        chkIAworkcompletedOT: false,
        chkDraftreportOT: false,
        chkClientdatadueOT: false,
        chkAnnouncementdeliveryOT: false,

        chkComment: false,
        chkFormStatus: false,
        chkIAAudit1: false,
        chkIAAudit1StartDate: false,
        chkIAAudit1EndDate: false,
        chkIAAudit2: false,
        chkIAAudit2StartDate: false,
        chkIAAudit2EndDate: false,
        radioexceltype: "LoanAudit"
      })

    }
    else if (this.ExcelToexportForm.value.radioexceltype == "FinancialAudit") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: false,
        chkAuditYear: true,
        chkClassification: true,
        chkGroupScope: true,
        chkClientContacts: false,
        chkClientEntityName: false,
        chkClientShortEntityName: true,
        chkCanton: false,
        chkBankCode: true,
        chkBankCodeNumeric: true,
        chkLocation: false,
        chkBankSize: true,
        //  chkEYOffice: false,
        chkEYRegion: true,
        chkIRRBOffice: true,
        chkReportingLanguage: true,
        chkFinancialAuditLeadRP: true,
        chkAuditPeriodStartDateRP: true,
        chkAuditPeriodEndDateRP: true,
        chkRegionalLeadPartnerRP: true,
        chkAssignedLeadAuditorRP: true,
        chkInternalAuditEngagementLeaderRP: true,
        chkAuditExecutionStartDateRP: true,
        chkAuditExecutionEndDateRP: true,
        chkAuditIdcodeRP: false,
        chkStatusRP: true,
        chkFinalreporttoclientdueRP: false,
        chkClientfeedbackdueRP: false,
        chkDraftreporttoclientdueRP: false,
        chkIAworkpaperreviewdueRP: false,
        chkIAworkcompletedRP: true,
        chkDecisiveDate:true,
        chkLatestSignoffDate:true,
        chkActualSignoffDate:true,
        chkDraftreportRP: false,
        chkClientdatadueRP: false,
        chkAnnouncementdeliveryRP: false,
        chkMeetingFormat: false,
        chkAGMDate: false,
        chkAGMStartTime: false,
        chkAGMEndTime: false,
        chkAGMLocation: false,
        chkEYRepresentative: false,
        chkAGMNotes: false,
        chkFinancialAuditLeadKP: false,
        chkRegionalLeadPartnerKP: false,
        chkAssignedLeadAuditorKP: false,
        chkInternalAuditEngagementLeaderKP: false,
        chkAuditExecutionStartDateKP: false,
        chkAuditExecutionEndDateKP: false,
        chkAuditIdcodeKP: false,
        chkStatusKP: false,
        chkFinalreporttoclientdueKP: false,
        chkClientfeedbackdueKP: false,
        chkDraftreporttoclientdueKP: false,
        chkIAworkpaperreviewdueKP: false,
        chkIAworkcompletedKP: false,
        chkDraftreportKP: false,
        chkClientdatadueKP: false,
        chkAnnouncementdeliveryKP: false,

        chkFinancialAuditLeadAP: false,
        chkAuditPeriodStartDateAP: false,
        chkAuditPeriodEndDateAP: false,
        chkRegionalLeadPartnerAP: false,
        chkAssignedLeadAuditorAP: false,
        chkInternalAuditEngagementLeaderAP: false,
        chkAuditExecutionStartDateAP: false,
        chkAuditExecutionEndDateAP: false,
        chkAuditIdcodeAP: false,
        chkStatusAP: false,
        chkregAuditCoSign:false,
        chkFinalreporttoclientdueAP: false,
        chkClientfeedbackdueAP: false,
        chkDraftreporttoclientdueAP: false,
        chkIAworkpaperreviewdueAP: false,
        chkIAworkcompletedAP: false,
        chkDraftreportAP: false,
        chkClientdatadueAP: false,
        chkAnnouncementdeliveryAP: false,

        chkFinancialAuditLeadOT: false,
        chkAuditPeriodStartDateOT: false,
        chkAuditPeriodEndDateOT: false,
        chkRegionalLeadPartnerOT: false,
        chkAssignedLeadAuditorOT: false,
        //chkAssignedAuditTeamMembersOT: false,
        chkAuditExecutionStartDateOT: false,
        chkAuditExecutionEndDateOT: false,
        chkAuditIdcodeOT: false,
        chkStatusOT: false,
        chkFinalreporttoclientdueOT: false,
        chkClientfeedbackdueOT: false,
        chkDraftreporttoclientdueOT: false,
        chkIAworkpaperreviewdueOT: false,
        chkIAworkcompletedOT: false,
        chkDraftreportOT: false,
        chkClientdatadueOT: false,
        chkAnnouncementdeliveryOT: false,

        chkComment: false,
        chkFormStatus: false,
        chkIAAudit1: false,
        chkIAAudit1StartDate: false,
        chkIAAudit1EndDate: false,
        chkIAAudit2: false,
        chkIAAudit2StartDate: false,
        chkIAAudit2EndDate: false,
        radioexceltype: "FinancialAudit"
      })

    }
    else if (this.ExcelToexportForm.value.radioexceltype == "Custom") {
      this.ExcelToexportForm.setValue({
        chkFormTitle: false,
        chkAuditYear: false,
        chkClassification: false,
        chkGroupScope: false,
        chkClientContacts: false,
        chkClientEntityName: false,
        chkClientShortEntityName: false,
        chkCanton: false,
        chkBankCode: false,
        chkBankCodeNumeric: false,
        chkLocation: false,
        chkBankSize: false,
        //  chkEYOffice: false,
        chkEYRegion: false,
        chkIRRBOffice: false,
        chkReportingLanguage: false,
        chkFinancialAuditLeadRP: false,
        chkAuditPeriodStartDateRP: false,
        chkAuditPeriodEndDateRP: false,
        chkRegionalLeadPartnerRP: false,
        chkAssignedLeadAuditorRP: false,
        chkInternalAuditEngagementLeaderRP: false,
        chkAuditExecutionStartDateRP: false,
        chkAuditExecutionEndDateRP: false,
        chkAuditIdcodeRP: false,
        chkStatusRP: false,
        chkFinalreporttoclientdueRP: false,
        chkClientfeedbackdueRP: false,
        chkDraftreporttoclientdueRP: false,
        chkIAworkpaperreviewdueRP: false,
        chkIAworkcompletedRP: false,
        chkDecisiveDate:false,
        chkLatestSignoffDate:false,
        chkActualSignoffDate:false,
        chkDraftreportRP: false,
        chkClientdatadueRP: false,
        chkAnnouncementdeliveryRP: false,
        chkMeetingFormat: false,
        chkAGMDate: false,
        chkAGMStartTime: false,
        chkAGMEndTime: false,
        chkAGMLocation: false,
        chkEYRepresentative: false,
        chkAGMNotes: false,
        chkFinancialAuditLeadKP: false,
        chkRegionalLeadPartnerKP: false,
        chkAssignedLeadAuditorKP: false,
        chkInternalAuditEngagementLeaderKP: false,
        chkAuditExecutionStartDateKP: false,
        chkAuditExecutionEndDateKP: false,
        chkAuditIdcodeKP: false,
        chkStatusKP: false,
        chkFinalreporttoclientdueKP: false,
        chkClientfeedbackdueKP: false,
        chkDraftreporttoclientdueKP: false,
        chkIAworkpaperreviewdueKP: false,
        chkIAworkcompletedKP: false,
        chkDraftreportKP: false,
        chkClientdatadueKP: false,
        chkAnnouncementdeliveryKP: false,

        chkFinancialAuditLeadAP: false,
        chkAuditPeriodStartDateAP: false,
        chkAuditPeriodEndDateAP: false,
        chkRegionalLeadPartnerAP: false,
        chkAssignedLeadAuditorAP: false,
        chkInternalAuditEngagementLeaderAP: false,
        chkAuditExecutionStartDateAP: false,
        chkAuditExecutionEndDateAP: false,
        chkAuditIdcodeAP: false,
        chkStatusAP: false,
        chkregAuditCoSign:false,
        chkFinalreporttoclientdueAP: false,
        chkClientfeedbackdueAP: false,
        chkDraftreporttoclientdueAP: false,
        chkIAworkpaperreviewdueAP: false,
        chkIAworkcompletedAP: false,
        chkDraftreportAP: false,
        chkClientdatadueAP: false,
        chkAnnouncementdeliveryAP: false,

        chkFinancialAuditLeadOT: false,
        chkAuditPeriodStartDateOT: false,
        chkAuditPeriodEndDateOT: false,
        chkRegionalLeadPartnerOT: false,
        chkAssignedLeadAuditorOT: false,
        //chkAssignedAuditTeamMembersOT: false,
        chkAuditExecutionStartDateOT: false,
        chkAuditExecutionEndDateOT: false,
        chkAuditIdcodeOT: false,
        chkStatusOT: false,
        chkFinalreporttoclientdueOT: false,
        chkClientfeedbackdueOT: false,
        chkDraftreporttoclientdueOT: false,
        chkIAworkpaperreviewdueOT: false,
        chkIAworkcompletedOT: false,
        chkDraftreportOT: false,
        chkClientdatadueOT: false,
        chkAnnouncementdeliveryOT: false,

        chkComment: false,
        chkFormStatus: false,
        chkIAAudit1: false,
        chkIAAudit1StartDate: false,
        chkIAAudit1EndDate: false,
        chkIAAudit2: false,
        chkIAAudit2StartDate: false,
        chkIAAudit2EndDate: false,
        radioexceltype: "Custom"
      })

    }
  }
  //EndRegion



  getAuditTrail() {
    this.style.height = (document.documentElement.clientHeight - 200) + "px";

    this.currentactivetab = 'Audit'

    this.AuditTrailBuildHeaders();


    this._auditassignmentService.getAuditTrail()
      .subscribe(
        res => {

          console.log(res);
          this.AuditAssignmentAuditDataSet = res;
          this.AuditTrailfilterDataset = res;
          this.AuditTrailDataset = res;
          

        });





  }
  AuditTrailBuildHeaders() {


    this.AuditAssignmentColumnDefs = [


      {
        headerName: 'Audit Assignment',
        headerClass: 'cs-ag-grid-header-one',
        width: 2491,
        children:
          [
            {
              headerName: 'Form Title', field: 'formTitle', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              width: 500, autoHeight: true
            },


            {
              headerName: 'Audit Date', field: 'dateTime', sortable: true,
              filter: 'agDateColumnFilter',
              unSortIcon: true,
              cellClass: "ag-grid-cell-wrap-text",
              wrapText: true,
              autoHeight: true,
              width: 180,
              resizable: true,
              cellRenderer: (data) => {

                return moment(data.value).format('DD.MM.YYYY')
              },
              filterParams: {
                buttons: ['reset'],

                comparator: function (filterLocalDate, cellValue) {


                  var dateAsString = cellValue;

                  if (dateAsString == null) {
                    return 0;
                  }


                  var date = dateAsString.split('T')[0];
                  var dateParts = date.split('-');

                  var day = Number(dateParts[2]);
                  var month = parseInt(dateParts[1]) - 1;
                  var year = Number(dateParts[0]);
                  var cellDate = moment(year + "/" + month + "/" + day, "YYYY/mm/DD");

                  // Now that both parameters are Date objects, we can compare
                  if (cellDate < filterLocalDate) {
                    return -1;
                  } else if (cellDate > filterLocalDate) {
                    return 1;
                  }
                  return 0;
                }
              }
            }




            , {
              headerName: 'Action', field: 'action', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'close',
              width: 180
            },
            // {
            //   headerName: 'Row Count', field: 'rowCount', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'close',
            //   width: 180
            // }





            {
              headerName: 'New Values', field: 'newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "auditassignmentlist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.newValue != null && params.newValue != null)
                    return JSON.stringify(params.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },
            {
              headerName: 'Old Values', field: 'oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "auditassignmentlist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.oldValue != null && params.oldValue != null)
                    return JSON.stringify(params.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter
                , columnGroupShow: 'open'
              }
            }





            // , { headerName: 'Old Value', field: 'oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }
            //  , { headerName: 'New Value', field: 'newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }


          ]
      },

      , {
        headerName: 'RP',
        headerClass: 'cs-ag-grid-header-RP',
        children:
          [
            {
              headerName: 'New Values', field: 'auditTypeRP.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditTypeRP != null && params.data.auditTypeRP.newValue != null)

                    return JSON.stringify(params.data.auditTypeRP.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },
            {
              headerName: 'Old Values', field: 'auditTypeRP.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditTypeRP != null && params.data.auditTypeRP.oldValue != null)

                    return JSON.stringify(params.data.auditTypeRP.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }





          ]
      }, {
        headerName: 'AGM-RP',
        headerClass: 'cs-ag-grid-header-AGM',
        children:
          [
            {
              headerName: 'New Values', field: 'auditAGMDetails.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditAGMDetails != null && params.data.auditAGMDetails.newValue != null)

                    return JSON.stringify(params.data.auditAGMDetails.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },
            {
              headerName: 'Old Values', field: 'auditAGMDetails.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditAGMDetails != null && params.data.auditAGMDetails.oldValue != null)

                    return JSON.stringify(params.data.auditAGMDetails.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }





          ]
      },

      {
        headerName: 'KP',
        headerClass: 'cs-ag-grid-header-KP',
        children:
          [

            {
              headerName: 'New Values', field: 'auditTypeKP.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditTypeKP != null && params.data.auditTypeKP.newValue != null)

                    return JSON.stringify(params.data.auditTypeKP.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },

            {
              headerName: 'Old Values', field: 'auditTypeKP.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditTypeKP != null && params.data.auditTypeKP.oldValue != null)

                    return JSON.stringify(params.data.auditTypeKP.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }






          ]
      },

      {
        headerName: 'AP',
        headerClass: 'cs-ag-grid-header-AP',
        children:
          [


            {
              headerName: 'New Values', field: 'auditTypeAP.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditTypeAP != null && params.data.auditTypeAP.newValue != null)

                    return JSON.stringify(params.data.auditTypeAP.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },

            {
              headerName: 'Old Values', field: 'auditTypeAP.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditTypeAP != null && params.data.auditTypeAP.oldValue != null)

                    return JSON.stringify(params.data.auditTypeAP.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }
          ]
      },
      {
        headerName: 'IA',
        headerClass: 'cs-ag-grid-header-IR',
        children:
          [
            {
              headerName: 'New Values', field: 'auditTypeIA.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditTypeIA != null && params.data.auditTypeIA.newValue != null)

                    return JSON.stringify(params.data.auditTypeIA.newValue)
                  else return null
                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },

            {
              headerName: 'Old Values', field: 'auditTypeIA.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditTypeIA != null && params.data.auditTypeIA.oldValue != null)

                    return JSON.stringify(params.data.auditTypeIA.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }
          ]
      }, {
        headerName: 'Other',
        headerClass: 'cs-ag-grid-header-OR',
        children:
          [


            {
              headerName: 'New Values', field: 'auditTypeOther.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {

                valueGetter: params => {
                  if (params.data.auditTypeOther != null && params.data.auditTypeOther.newValue != null)

                    return JSON.stringify(params.data.auditTypeOther.newValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            },

            {
              headerName: 'Old Values', field: 'auditTypeOther.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true,
              cellRenderer: "audittypelist",
              autoHeight: true,
              width: 384,
              filterParams: {
                valueGetter: params => {
                  if (params.data.auditTypeOther != null && params.data.auditTypeOther.oldValue != null)

                    return JSON.stringify(params.data.auditTypeOther.oldValue)
                  else return null

                },
                textCustomComparator: this.agcustomauditlogfilter

              }
            }







            // { headerName: 'Old Value', field: 'auditTypeOther.oldValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }
            // , { headerName: 'New Value', field: 'auditTypeOther.newValue', sortable: true, filter: 'agTextColumnFilter', unSortIcon: true, resizable: true, columnGroupShow: 'closed' }


          ]
      }


    ]


  }


  audittrailfilter() {



    let filteredset = null;

    let audittypes = [];

    if (this.adtinsertshow) {
      audittypes.push("Insert")
    }

    if (this.adtdeleteshow) {
      audittypes.push("Delete")
    }

    if (this.adtbulkinsertshow) {
      audittypes.push("Bulk Upload")
    }
    if (this.adtupdateshow) {
      audittypes.push("Update")
    }



    filteredset = this.AuditTrailfilterDataset.filter(function (i, n) {

      return audittypes.indexOf(i.action) + 1 > 0;
    });


    this.AuditTrailDataset = filteredset;



  }

  ////#region Action check method
  ActionsCheck(action) {
    if (this.actionAccess.indexOf(action) != -1)
      return true;
    else
      return false;
  }
  setDivHeight() {

    this.styleDIV.width = "100%";
    this.styleDIV.height = (document.documentElement.clientHeight - 113) + "px";
  }
  TempleateExport() {
    this.toastflag = false;


    this._auditassignmentService.TempleateExport()
      .subscribe(
        res => {


          saveAs(res, "AuditassignmentTemplate.xlsx");

        },
        err => {

          console.log(err);
        }
      )
  }

  ////#endregion
  agcustomauditlogfilter(filter, value, filterText) {

    let filterTextLowerCase = filterText.toLowerCase();


    if (value != null && value != undefined && value != "null") {
      var obj = JSON.parse(value);
      let values = Object.keys(obj).map((key) => {



        if (obj[key] != null) {
          return (key + " : " + obj[key]).toLowerCase();
        }


      });

      let valueLowerCase = values.join(" ");

      //  let valueLowerCase = JSON.parse(value).toString().toLowerCase();

      switch (filter) {
        case 'contains':
          return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
          return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'equals':
          return valueLowerCase === filterTextLowerCase;
        case 'notEqual':
          return valueLowerCase != filterTextLowerCase;
        case 'startsWith':
          return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'endsWith':
          var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
          return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
        default:
          // should never happen
          console.warn('invalid filter type ' + filter);
          return false;
      }
    } else return false
  }

  handleauditGridReady(params) {
    this.auditgridApi = params;
    // this.auditgridApi.api.sizeColumnsToFit()
  }
  onFilterTextBoxChanged() {
    
    if (this.gridApi != null ||this.gridApi !=undefined)
      this.gridApi.api.setQuickFilter(this.ngSearchFilter);
  }
  onColumnResized(params) {
    params.api.resetRowHeights();
  }

  onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  onDeleteClick(e) {
    this.AuditAssignmentId = e.rowData.auditAssignmentId;

    this.deletemodalflag = true;
  }
  DeleteData() {

    this.toastflag = false;
    var obj = {
      id: this.AuditAssignmentId
    }
    this._auditassignmentService.DeleteData(obj)
      .subscribe(
        res => {

          if (res) {

            this.toasttype = "success";
            this.toastflag = true;
            this.deletemodalflag = false;
            this.toastmessage = "Audit Assignment has been deleted successfully.";
            this.GetAuditassignmentDetils();
            this.AuditAssignmentId = 0;
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
          else {
            this.toasttype = "error";
            this.toastflag = true;
            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {
          this.toasttype = "error";
          this.toastflag = true;
          this.toastmessage = "Error occured. Please try after sometime";
          console.log(err);
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);
        }
      )
  }
  public RoleBasedRegion: any;
  GetRoleBasedRegion() {

    this._auditassignmentService.GetRoleBasedRegion()
      .subscribe(
        res => {

          this.RoleBasedRegion = res;


        },
        err => {

          console.log(err);
        }
      )

  }
  private MassUpdateFormAuditAssignment(): FormGroup {
    return this._formBuilder.group({
      formstatus: [null, [Validators.required]],
      comment: []
    });
  }
  onbtnclickMassUpdate() {
    let selectedRows;
    selectedRows = this.gridApi.api.getSelectedRows();
    if (selectedRows.length === 0) {
      this.toasttype = "warning";
      this.toastflag = true;
      this.toastmessage = "Please select at least one contact";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    this.MassUpdateForm.reset();
    this.MassUpdateFlag = true;
  }
  GetFormStatusData() {

    this._auditassignmentService.GetFormStatusData()
      .subscribe(
        res => {
          this.lstFormStatus = res;
        },
        err => {

          console.log(err);
        }
      )

  }
  validateForm(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
  MassUpdateAssignmentData(form: FormGroup) {
    this.validateForm(form);
    if (!form.valid)
      return

    let selectedNodes = this.gridApi.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data.auditAssignmentId);
    var obj = {
      selectedData: selectedData,
      formstatus: form.value.formstatus,
      comment: form.value.comment
    }
    this._auditassignmentService.MassUpdateAssignmentData(obj)
      .subscribe(
        res => {

          if (res["message"] == "success") {
            this.toasttype = "success";
            this.toastflag = true;

            this.toastmessage = "Audit Assignments updated successfully.";
            this.GetAuditassignmentDetils();
            this.MassUpdateFlag = false;
            this.MassUpdateForm.reset();
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);
          }
          else if (res["message"] == "fail") {
            this.toasttype = "error";
            this.toastflag = true;

            this.toastmessage = "Error occured please contact support team";
            setTimeout(() => {
              this.toastflag=false;
             }, 3000);

          }
        },
        err => {

          this.toasttype = "error";
          this.toastflag = true;

          this.toastmessage = "Error occured please contact support team";
          setTimeout(() => {
            this.toastflag=false;
           }, 3000);

        }
      )

  }
  //#region Filters code

  GeneratYear() {
    var year = new Date().getFullYear();
    year = 2021;
    this.lstYear.push(year);
    for (var i = 1; i < 11; i++) {
      this.lstYear.push(year + i);
    }
  }
  public eyRegionMasterData: any;
  public irRBofficeMasterData: any;
  getRegionData() {
    this._auditassignmentService.getRegionData()
      .subscribe(
        res => {
          console.log(res);

          this.eyRegionMasterData = res[0];
          this.irRBofficeMasterData = res[1];



        },
        err => {

          console.log(err);
        }
      )

  }
  externalYearFilterChanged(newValue) {

    filterYear = newValue;
    this.gridApi.api.onFilterChanged();
  }
  externalRegionFilterChanged(newValue) {

    filterRegion = newValue;
    this.gridApi.api.onFilterChanged();

  }
  externalIROfficeFilterChanged(newValue) {

    filterIrOffice = newValue;
    this.gridApi.api.onFilterChanged();

  }
  doesExternalFilterPass(node) {

    if (filterRegion != "" && filterYear != "" && filterIrOffice != "") {
      if (node.data.auditYear == filterYear && node.data.eyregionName == filterRegion && node.data.irofficeName == filterIrOffice)
        return true;
    }
    else if (filterYear != "" && filterIrOffice != "") {
      if (node.data.auditYear == filterYear && node.data.irofficeName == filterIrOffice)
        return true;
    }
    else if (filterRegion != "" && filterYear != "") {
      if (node.data.auditYear == filterYear && node.data.eyregionName == filterRegion)
        return true;
    }
    else if (filterRegion != "" && filterIrOffice != "") {
      if (node.data.eyregionName == filterRegion && node.data.irofficeName == filterIrOffice)
        return true;
    }
    else if (filterRegion != "") {
      if (node.data.eyregionName == filterRegion)
        return true;
    }
    else if (filterYear != "") {
      if (node.data.auditYear == filterYear)
        return true;
    }
    else if (filterIrOffice != "") {
      if (node.data.irofficeName == filterIrOffice)
        return true;
    }
    else
      return false;
  }
  resetFIlters() {

    //this.lstSearchFilterFunction;
    // this.GetSearchFilterMastersData();
    this.NgSearchFlag = false;
    filterYear = "", filterRegion = ""; filterIrOffice = "";
    //this.selectedYear = null;
    this.RegionSelected = null;
    this.irofficeSelected = null;
    this.BankSelected="allBanks";
    this.isMyBanks=false;
    this.tableDataset=this.originaldata;
    //this.GetAuditassignmentDetils();
    setTimeout(() => {
      this.NgSearchFlag = true;
    }, 50);
    this.gridApi.api.onFilterChanged();
  }
  isExternalFilterPresent() {
    return filterYear != "" || filterRegion != "" || filterIrOffice != "";
  }
  onDownloadAGMICSFile(e){
    
    let starttime=[],endtime=[],startdate=null,Enddate=null;
    var description='<p><strong>Bank:</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+ 
    e.rowData.formTitle+'</p><p><strong>Meeting Format:</strong>&nbsp;&nbsp;&nbsp;&nbsp;'+ e.rowData.meetingFormat +'</p><p><strong>EY Representative:</strong>&nbsp;'+e.rowData.eyRepresentative +'</p><p>&nbsp;</p><p><strong>Notes:</strong> </p><p>'+
    e.rowData.notes +'</p>';
       
    if(e.rowData.startTime !=null && e.rowData.startTime!=undefined && e.rowData.startTime!="" ){
        starttime=e.rowData.startTime.split(':');
         startdate=moment(e.rowData.agmDate).set("hour", starttime[0]).set("minute", starttime[1]).format('YYYY-MM-DDTH:mm:ss');
    }
    if(e.rowData.endTime !=null && e.rowData.endTime!=undefined && e.rowData.endTime!="" ){
        endtime=e.rowData.endTime.split(':');   
     Enddate=moment(e.rowData.agmDate).set("hour", endtime[0]).set("minute", endtime[1]).format('YYYY-MM-DDTH:mm:ss');
    }
    let calendarData = [
      'data:text/calendar;charset=utf8,',
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'ATTENDEE:MAILTO:'+e.rowData.eyRepresentativeEmail,
      'X-ALT-DESC;FMTTYPE=text/html:' + description,
      'DTSTART:' + startdate,
      'DTEND:' + Enddate,
      'LOCATION:' + e.rowData.agmLocation,
      'SUMMARY:' + e.rowData.meetingFormat +' - '+e.rowData.formTitle,
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
      'END:VCALENDAR',
      'UID:' + '1',
      'DTSTAMP:' + new Date(e.rowData.agmDate).toISOString(),
      'PRODID:website-1.0'
    ].join('\n');
    if(startdate==null && Enddate==null){
      this.toasttype = "warning";
      this.toastflag = true;

      this.toastmessage = "AMG meeting was not scheduled for the audit assignment.";
      setTimeout(() => {
        this.toastflag=false;
       }, 3000);
      return
    }
    saveAs(calendarData, e.rowData.meetingFormat +' - '+e.rowData.formTitle+".ics");
   //window.open(calendarData);
  }
  //#endregion
}
var filterYear = "", filterRegion = "", filterIrOffice = "";
